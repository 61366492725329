import React from 'react'
import { Delimiter } from './'
import { Typography, Row, Space } from '@hindawi/phenom-ui'

const { Text } = Typography

type Reviewer = {
  status: string
}

const ReviewerReportStatus = ({
  numOfReviewers,
  label,
  addComma,
  showWhenZero,
}) => (
  <>
    {(showWhenZero || numOfReviewers !== 0) && (
      <div>
        <>
          <Text className="bold">{numOfReviewers}</Text>
          <Delimiter />
          <span>{label}</span>
          {addComma && (
            <>
              <span>,</span>
              <div
                style={{
                  display: 'inline-block',
                  width: '4px',
                }}
              ></div>
            </>
          )}
        </>
      </div>
    )}
  </>
)

const getNumOfReviewers = (reviewers, status?: string) =>
  status
    ? reviewers.filter((reviewer) => reviewer.status === status).length
    : reviewers.length

export const ReviewerReportCell = ({
  reviewers,
}: {
  reviewers: Reviewer[]
}) => {
  const numOfReviewersAccepted = getNumOfReviewers(reviewers, 'accepted')
  const numOfReviewersDeclined = getNumOfReviewers(reviewers, 'declined')
  const numOfReviewersSubmitted = getNumOfReviewers(reviewers, 'submitted')
  const numOfReviewers = getNumOfReviewers(reviewers)

  return (
    <Row className="reviewer-report-cell">
      <Text className="bold">Reviewer Reports:</Text>
      <Delimiter />
      <Space size={0} className="reviewers-report-status">
        <ReviewerReportStatus
          numOfReviewers={numOfReviewers}
          label="Invited"
          addComma={
            !!numOfReviewersAccepted ||
            !!numOfReviewersDeclined ||
            !!numOfReviewersSubmitted
          }
          showWhenZero={true}
        />
        <ReviewerReportStatus
          numOfReviewers={numOfReviewersAccepted}
          label="Agreed"
          addComma={!!numOfReviewersDeclined || !!numOfReviewersSubmitted}
          showWhenZero={false}
        />
        <ReviewerReportStatus
          numOfReviewers={numOfReviewersDeclined}
          label="Declined"
          addComma={!!numOfReviewersSubmitted}
          showWhenZero={false}
        />
        <ReviewerReportStatus
          numOfReviewers={numOfReviewersSubmitted}
          label="Submitted"
          addComma={false}
          showWhenZero={false}
        />
      </Space>
    </Row>
  )
}
