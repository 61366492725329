import React, { Fragment } from 'react'
import { camelCase, get } from 'lodash'
import { useJournal } from '../../../component-journal-info'
import {
  ActionLink,
  Text,
  Item,
  Label,
  validators,
  ValidatedFormField,
} from '@hindawi/ui'
import { Input, Radio, Row, Space, Title } from '@hindawi/phenom-ui'
const { TextArea } = Input
import { useCurrentUser } from '../../../component-authentication/client'

import { WizardAuthors, WizardPreprint } from '.'
import { visibleDeclarations, checkIfCanBeSubmittedToSI } from '..'
import { JournalTransfer } from './step-three-components'
import { Field } from 'formik'
import styled from 'styled-components'

const YES_OR_NO_OPTIONS = [
  { value: 'yes', label: 'Yes' },
  { value: 'no', label: 'No' },
]

const getArticleTypes = ({ journal, formValues }) => {
  const isSpecialIssue = formValues.issueType === 'specialIssue'
  return isSpecialIssue
    ? journal.articleTypes
        .filter((articleType) => checkIfCanBeSubmittedToSI(articleType.name))
        .map((articleType) => ({
          label: articleType.name,
          value: articleType.id,
        }))
    : journal.articleTypes.map((articleType) => ({
        label: articleType.name,
        value: articleType.id,
      }))
}

const defineVisibilities = ({
  declarations,
  formValues,
  articleTypesOptions,
}) => {
  if (formValues.meta.articleTypeId && articleTypesOptions.length) {
    const getArticleTypeName = (id) => {
      const articleLabel = articleTypesOptions.find(
        (at) => at.value === id,
      ).label
      return camelCase(articleLabel)
    }

    const articleTypeName = getArticleTypeName(formValues.meta.articleTypeId)

    if (declarations[articleTypeName]) {
      return {
        isConflictsVisible:
          declarations[articleTypeName].conflictOfInterest.isVisible,
        isDataAvailabilityVisible:
          declarations[articleTypeName].dataAvailability.isVisible,
        isFundingStatementVisible:
          declarations[articleTypeName].fundingStatement.isVisible,
        isDataAvailabilityRequired:
          declarations[articleTypeName].dataAvailability.isRequired,
      }
    }
  }
  return {}
}

function conditionallyRenderAbstract(submissionEditorialModel) {
  if (submissionEditorialModel && !submissionEditorialModel.hasAbstract) return
  return (
    <Row mb={4}>
      <Item data-test-id="submission-abstract" vertical>
        <Label required>Abstract</Label>
        <ValidatedFormField
          component={AbstractTextArea}
          name="meta.abstract"
          validate={[validators.required]}
        />
      </Item>
    </Row>
  )
}

const WizardStepThree = ({
  journal,
  formValues,
  isAuthorEdit,
  setFieldValue,
  getTooltipContent,
  wizardErrors,
  setStatus,
  isAuthorEmailEditable,
  submissionEditorialModel,
  ...rest
}) => {
  const {
    links: { coiLink, dataAvailabilityLink },
  } = useJournal()
  const currentUser = useCurrentUser()

  const showIsTransferSection = ['admin', 'editorialAssistant'].includes(
    currentUser.role,
  )

  const { declarations, questions } = visibleDeclarations

  const setWizardEditMode = (value) => {
    setFieldValue('isEditing', value)
  }

  const articleTypesOptions = getArticleTypes({
    journal,
    formValues,
  })

  const {
    isConflictsVisible,
    isDataAvailabilityVisible,
    isFundingStatementVisible,
    isDataAvailabilityRequired,
  } = defineVisibilities({
    declarations,
    formValues,
    journal,
    articleTypesOptions,
  })

  const { preprintValue } = formValues
  const { preprints, preprintDescription } = journal

  return (
    <Fragment>
      <Title level={4} style={{ textAlign: 'center' }}>
        3. Manuscript & Author Details
      </Title>
      <Row
        flexDirection="column"
        style={{ marginBottom: '24px' }}
        align="center"
      >
        <Text align="center" mb={2} mt={2} secondary>
          Please provide the details of all the authors of this manuscript, in
          the order that they appear on the manuscript.
        </Text>
        <Text align="center" secondary>
          Your details have been prefilled as the submitting author.
        </Text>
      </Row>

      <Row mb={2}>
        <Item data-test-id="submission-title" flex={3} vertical>
          <Label required>Manuscript Title</Label>
          <ValidatedFormField
            component={Input}
            inline
            name="meta.title"
            validate={[validators.required]}
          />
        </Item>
      </Row>
      {conditionallyRenderAbstract(submissionEditorialModel)}

      {showIsTransferSection && (
        <JournalTransfer
          formValues={formValues}
          setFieldValue={setFieldValue}
          validators={validators}
        />
      )}

      <WizardAuthors
        formValues={formValues}
        isAuthorEmailEditable={isAuthorEmailEditable}
        journal={journal}
        setWizardEditMode={setWizardEditMode}
        wizardErrors={wizardErrors}
        {...rest}
      />

      {!!preprints.length && (
        <WizardPreprint
          preprintDescription={preprintDescription}
          preprints={preprints}
          preprintValue={preprintValue}
          setFieldValue={setFieldValue}
        />
      )}

      {formValues.meta.articleTypeId && (
        <Fragment>
          {isConflictsVisible && (
            <Row mt={6}>
              <Item vertical>
                <Label mb={2} required={isConflictsVisible}>
                  {questions.conflictOfInterest.title}
                </Label>
                <Text display="inline" mb={2} secondary>
                  {questions.conflictOfInterest.subtitle}{' '}
                  <ActionLink display="inline" secondary to={coiLink}>
                    here.
                  </ActionLink>
                </Text>
                <Field name="meta.hasConflictOfInterest">
                  {({ field }) => (
                    <Radio.Group
                      name="meta.hasConflictOfInterest"
                      onChange={(e) => {
                        setFieldValue('meta', {
                          ...formValues.meta,
                          conflictOfInterest: '',
                        })

                        field.onChange(e)
                      }}
                      defaultValue={field.value}
                      data-test-id="conflict-of-interest-yes-no-radio-group"
                    >
                      <Space
                        direction="horizontal"
                        style={{ marginBottom: '20px' }}
                      >
                        {YES_OR_NO_OPTIONS.map(({ label, value }) => (
                          <Radio
                            key={value}
                            value={value}
                            checked={field.value}
                          >
                            {label}
                          </Radio>
                        ))}
                      </Space>
                    </Radio.Group>
                  )}
                </Field>
                {get(formValues, 'meta.hasConflictOfInterest') === 'yes' && (
                  <ValidatedFormField
                    component={TextArea}
                    name="meta.conflictOfInterest"
                    placeholder={questions.conflictOfInterest.placeholder}
                    validate={[validators.required]}
                  />
                )}
              </Item>
            </Row>
          )}

          {isDataAvailabilityVisible && (
            <Row>
              <Item vertical>
                <Label mb={2} required={isDataAvailabilityRequired}>
                  {questions.dataAvailability.title}
                </Label>
                <Text display="inline" mb={2} secondary>
                  {questions.dataAvailability.subtitle}{' '}
                  <ActionLink
                    display="inline"
                    secondary
                    to={dataAvailabilityLink}
                  >
                    here.
                  </ActionLink>
                </Text>

                <ValidatedFormField
                  component={TextArea}
                  name="meta.dataAvailability"
                  placeholder={questions.dataAvailability.placeholder}
                  validate={
                    isDataAvailabilityRequired ? [validators.required] : []
                  }
                />
              </Item>
            </Row>
          )}

          {isFundingStatementVisible && (
            <Row>
              <Item vertical>
                <Label mb={2} required>
                  {questions.fundingStatement.title}
                </Label>
                <Text mb={2} secondary>
                  {questions.fundingStatement.subtitle}
                </Text>
                <ValidatedFormField
                  component={TextArea}
                  name="meta.fundingStatement"
                  placeholder={questions.fundingStatement.placeholder}
                  validate={[validators.required]}
                />
              </Item>
            </Row>
          )}
        </Fragment>
      )}
    </Fragment>
  )
}

const AbstractTextArea = styled(TextArea)`
  min-height: 120px !important;
`
export default WizardStepThree
