import styled from 'styled-components'

export const RightContainer = styled.div`
  align-items: center;
  display: flex;
  height: 70px;
  margin-right: 9px;
  right: 0;
  top: 0;
`

export const LogoContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: start;
  height: 40px;
  z-index: 1;
  cursor: pointer;
`

export const Root = styled.header`
  align-items: center;
  background-color: white;
  box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.08);
  height: calc(4px * 18);
  display: flex;
  justify-content: space-between;
  padding: 0 24px;
  z-index: 200;
  position: sticky;
  top: 0;
`
