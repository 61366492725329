import React, { Fragment } from 'react'
import { get } from 'lodash'

import FileSection from './FileSection'

const COVER_LETTER_FILE_EXTENSIONS = [
  'pdf',
  'doc',
  'docx',
  'txt',
  'rtf',
  'odt',
  'png',
  'jpg',
  'jpeg',
]
const FIGURE_FILE_EXTENSION = [
  'avi',
  'bbl',
  'bib',
  'bib.bac',
  'bmp',
  'bst',
  'bz2',
  'cdx',
  'csv',
  'doc',
  'docx',
  'dotx',
  'emf',
  'eml',
  'enl',
  'eps',
  'fig',
  'gif',
  'html',
  'int',
  'jpg',
  'jxr',
  'layout',
  'lyx',
  'MHT',
  'mp4',
  'mpeg',
  'nb',
  'odg',
  'odp',
  'odt',
  'opj',
  'pdf',
  'pgf',
  'png',
  'ppt',
  'pptm',
  'pptx',
  'psd',
  'pup',
  'py',
  'spl',
  'sty',
  'svg',
  'synctex.gz',
  'tex',
  'tif',
  'ttf',
  'txt',
  'vsd',
  'vsdm',
  'vsdx',
  'vssx',
  'webp',
  'wmf',
  'xlsx',
  'xlsx',
  'xltx',
  'xml',
  'xps',
]

const WizardFiles = ({
  files,
  compact,
  onUploadFile,
  onChangeList,
  onDeleteFile,
  manuscriptStatus,
  setIsFileUploading,
}) => {
  const isLastSection = manuscriptStatus !== 'qualityChecksRequested'

  return (
    <Fragment>
      <FileSection
        allowedFileExtensions={['pdf', 'doc', 'docx', 'txt', 'rtf', 'odt']}
        compact={compact}
        files={get(files, `manuscript`, []).filter(Boolean)}
        isFirst
        listName="manuscript"
        maxFiles={1}
        onChangeList={onChangeList}
        onDeleteFile={onDeleteFile}
        onUploadFile={onUploadFile}
        required
        setIsFileUploading={setIsFileUploading}
        title="Main Manuscript"
      />
      <FileSection
        allowedFileExtensions={COVER_LETTER_FILE_EXTENSIONS}
        compact={compact}
        files={get(files, `coverLetter`, []).filter(Boolean)}
        listName="coverLetter"
        maxFiles={1}
        onChangeList={onChangeList}
        onDeleteFile={onDeleteFile}
        onUploadFile={onUploadFile}
        setIsFileUploading={setIsFileUploading}
        title="Cover Letter"
      />
      <FileSection
        allowedFileExtensions={COVER_LETTER_FILE_EXTENSIONS}
        compact={compact}
        files={get(files, `supplementary`, []).filter(Boolean)}
        isLast={isLastSection}
        listName="supplementary"
        maxFiles={Number.MAX_SAFE_INTEGER}
        onChangeList={onChangeList}
        onDeleteFile={onDeleteFile}
        onUploadFile={onUploadFile}
        setIsFileUploading={setIsFileUploading}
        title="Supplemental Files"
      />
      {manuscriptStatus === 'qualityChecksRequested' && (
        <FileSection
          allowedFileExtensions={FIGURE_FILE_EXTENSION}
          compact={compact}
          files={get(files, `figure`, []).filter(Boolean)}
          isLast
          listName="figure"
          maxFiles={Number.MAX_SAFE_INTEGER}
          onChangeList={onChangeList}
          onDeleteFile={onDeleteFile}
          onUploadFile={onUploadFile}
          setIsFileUploading={setIsFileUploading}
          title="Figure Files"
        />
      )}
    </Fragment>
  )
}

export default WizardFiles
