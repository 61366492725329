import { useContext, useEffect } from 'react'
import { omit } from 'lodash'
import { localStorageService } from '../../../../../component-localstorage-service'
import { DashboardContext } from '../'
import {
  initialOption,
  Option,
  STATUS_FILTERS_KEY,
  SelectOptionT,
} from './state'

import { ManagerApiI } from '../../'
import { searchManuscriptInputResolverI } from '../manuscripts'

import { orderOptions, orderValues } from '../filters'
import {
  initialSelectedStatusCategories,
  SelectedStatusCategoriesT,
} from './statusCategories'
import { useCurrentUser } from '../../../../../component-authentication/client'

const storage = localStorageService()

const useFilterManager = ({ context }) => {
  const { value, set } = context

  const reset = () => {
    set(initialOption) // or empty string, pass from manager
  }

  return {
    value,
    set,
    reset,
  }
}

export const useJournalManager: () => ManagerApiI<Option, null> = () => {
  const context = useContext(DashboardContext).journal
  return useFilterManager({ context })
}

export const useSectionManager: () => ManagerApiI<Option, null> = () => {
  const context = useContext(DashboardContext).section
  return useFilterManager({ context })
}

export const useSpecialIssueManager: () => ManagerApiI<Option, null> = () => {
  const context = useContext(DashboardContext).specialIssue
  return useFilterManager({ context })
}

export const useStatusesManager = () => {
  const selectedStatusesContext = useContext(DashboardContext).selectedStatuses
  if (!selectedStatusesContext) {
    throw new Error('selectedStatuses context is undefined')
  }
  const { value, set } = selectedStatusesContext
  const updateSelectedStatuses = (
    updatedSelectedStatusCategories: SelectedStatusCategoriesT,
  ) => {
    storage.set(STATUS_FILTERS_KEY, updatedSelectedStatusCategories)

    set(updatedSelectedStatusCategories)
  }

  const reset = () => {
    storage.set(STATUS_FILTERS_KEY, initialSelectedStatusCategories)
    set(initialSelectedStatusCategories)
  }

  return {
    set: updateSelectedStatuses,
    reset,
    value,
  }
}

export const useRoleManager = () => {
  const roleContext = useContext(DashboardContext).role
  if (!roleContext) {
    throw new Error('role context is undefined')
  }
  const { value, set } = roleContext
  const updateSelectedRole = (newValue) => {
    set(newValue)
  }
  return {
    set: updateSelectedRole,
    value,
  }
}

export const useAuthorManager = () => {
  const context = useContext(DashboardContext).author
  const reset = () => {
    if (context) return context.set('')
  }
  return {
    ...useFilterManager({ context }),
    reset,
  }
}

export const useAcademicEditorManager = () => {
  const context = useContext(DashboardContext).academicEditor
  const reset = () => {
    if (context) context.set('')
  }
  return {
    ...useFilterManager({ context }),
    reset,
  }
}

export const usePageManager = () => {
  const context = useContext(DashboardContext).page
  const reset = () => {
    if (context) return context.set(1)
  }
  return {
    ...useFilterManager({ context }),
    reset,
  }
}

export const useOrderCriteriaManager = () => {
  const context = useContext(DashboardContext).orderCriteria
  const reset = () => {
    if (context) return context.set(orderOptions.updated)
  }
  return {
    ...useFilterManager({ context }),
    reset,
  }
}

const getOrderParam = ({ orderCriteria }) => {
  if (orderCriteria.value) {
    switch (orderCriteria.value.value) {
      case orderValues.NEWEST_UPDATED:
        return { orderByDesc: 'updated' }
      case orderValues.OLDEST_UPDATED:
        return { orderBy: 'updated' }
      case orderValues.NEWEST_SUBMITTED:
        return { orderByDesc: 'submittedDate' }
      case orderValues.OLDEST_SUBMITTED:
        return { orderBy: 'submittedDate' }
      default:
        return {}
    }
  }
  return {}
}

type FiltersManagerParamsType = Partial<searchManuscriptInputResolverI> & {
  manuscripts: ManagerApiI<any, searchManuscriptInputResolverI>
  statusesCount?: ManagerApiI<any, searchManuscriptInputResolverI>
}

export interface FiltersPropsI {
  filters: ManagerApiI<any, FiltersManagerParamsType>
}

export interface FiltersManagerAPII {
  role: ManagerApiI<string, null>
  journal: ManagerApiI<Option, null>
  section: ManagerApiI<Option, null>
  specialIssue: ManagerApiI<Option, null>
  author: ManagerApiI<string, null>
  editor: ManagerApiI<string, null>
  page: ManagerApiI<number, null>
  handleFilterChange: any
  orderCriteria: ManagerApiI<SelectOptionT, null>
  statuses: ManagerApiI<SelectedStatusCategoriesT, null>
}

export type FiltersManagerI = (
  params: FiltersManagerParamsType,
) => FiltersManagerAPII

export const useFiltersManager: FiltersManagerI = (params) => {
  const { manuscripts, statusesCount } = params

  const currentUser = useCurrentUser()

  const role = useRoleManager()
  const journal = useJournalManager()
  const section = useSectionManager()
  const specialIssue = useSpecialIssueManager()

  const author = useAuthorManager()
  const editor = useAcademicEditorManager()
  const page = usePageManager()

  const orderCriteria = useOrderCriteriaManager()
  const statuses = useStatusesManager()

  const rangeStart = page.value ? (page.value - 1) * 10 : 0
  const rangeEnd = page.value ? page.value * 10 - 1 : 9

  const selectedStatuses = statuses.value
    .reduce((acc, curr) => [...acc, ...curr.selectedValues], [])
    .join(',')

  const resolvePayload = () => ({
    role: currentUser.role,
    journalId: journal.value.value || undefined,
    // because there are inconsistencies in the DB in the sense that some manuscripts have both sectionId and specialIssueId, but other ones do not,
    // we have to remove sectionId from the query when we have specialIssueId so we make sure we catch all manuscripts under a special issue
    sectionId:
      (!!journal.value.value &&
        !specialIssue.value.value &&
        section.value.value) ||
      undefined,
    specialIssueId:
      (!!journal.value.value && specialIssue.value.value) || undefined,
    academicEditorEmail: editor.value || undefined,
    ...(editor.value
      ? {
          academicEditorStatus: 'accepted',
        }
      : {}),
    authorEmail: author.value || undefined,
    rangeStart,
    rangeEnd,
    status: selectedStatuses,
    ...getOrderParam({ orderCriteria }),
    ...params,
  })

  const handleFilterChange = (resetPagination: boolean = true) => {
    if (resetPagination) {
      page.reset()
    }
    const payload = resolvePayload()

    if (manuscripts && manuscripts.fetch) {
      manuscripts.fetch(payload)
    }
    if (statusesCount && statusesCount.fetch) {
      statusesCount.fetch(omit(payload, 'status'))
    }
  }

  useEffect(() => {
    const t = setTimeout(() => {
      handleFilterChange()
    }, 0)

    return () => {
      clearTimeout(t)
    }
  }, [
    role.value,
    journal.value.value,
    section.value.value,
    specialIssue.value.value,
    orderCriteria.value.value,
    statuses.value,
    author.value,
    editor.value,
  ])

  useEffect(() => {
    const t = setTimeout(() => {
      handleFilterChange(false)
    }, 0)

    return () => {
      clearTimeout(t)
    }
  }, [page.value])

  return {
    role,
    journal,
    section,
    specialIssue,
    author,
    editor,
    page,
    handleFilterChange,
    orderCriteria,
    statuses,
  }
}
