import { get } from 'lodash'
import { compose } from 'recompose'
import { graphql } from 'react-apollo'

import * as queries from './queries'
import * as mutations from './mutations'

const manuscriptIdPath = 'match.params.manuscriptId'

const optionsFn = (props) => ({
  variables: {
    manuscriptId: get(props, manuscriptIdPath),
  },
})

export default compose(
  graphql(queries.getManuscriptAndActiveJournals, {
    options: optionsFn,
  }),
  graphql(mutations.createDraftManuscript, {
    name: 'createDraft',
  }),
  graphql(mutations.updateDraftManuscript, {
    name: 'updateDraft',
  }),
  graphql(mutations.addAuthorToManuscript, {
    name: 'addAuthorToManuscript',
  }),
  graphql(mutations.updateAutosave, {
    name: 'updateAutosave',
  }),
  graphql(mutations.removeAuthorFromManuscript, {
    name: 'removeAuthor',
  }),
  graphql(mutations.editAuthorFromManuscript, {
    name: 'editAuthor',
  }),
  graphql(mutations.addFileToManuscript, {
    name: 'addFileToManuscript',
  }),
  graphql(mutations.removeFileFromManuscript, {
    name: 'removeFileFromManuscript',
  }),
  // TODO: Is this still used anywhere? Seems like it's just declared in a bunch of places and what's actually used is getSignedUrl?
  graphql(mutations.getFileSignedUrl, { name: 'getFileSignedUrl' }),
  graphql(mutations.updateManuscriptFile, {
    name: 'updateManuscriptFile',
    options: (props) => ({
      refetchQueries: [
        {
          query: queries.getManuscriptAndActiveJournals,
          variables: {
            manuscriptId: get(props, manuscriptIdPath),
          },
        },
      ],
    }),
  }),
  graphql(mutations.submitManuscript, {
    name: 'submitManuscript',
    options: optionsFn,
  }),
  graphql(mutations.editManuscript, {
    name: 'editManuscript',
    options: optionsFn,
  }),
)
