import React, { Fragment } from 'react'
import { get } from 'lodash'
import { Row, Text, Label } from '@hindawi/ui'

import { WizardFiles } from './'
import { IconExpand, Title } from '@hindawi/phenom-ui'

const WizardStepFour = ({
  formValues,
  wizardErrors,
  onUploadFile,
  onDeleteFile,
  onChangeList,
  setIsFileUploading,
}) => (
  <Fragment>
    <Title level={4} style={{ textAlign: 'center' }}>
      4. Manuscript Files Upload
    </Title>
    <Row>
      <Text
        align="center"
        display="inline-block"
        mb={1 / 2}
        mt={1 / 2}
        secondary
      >
        <Row>
          Drag & Drop files in the specific section or click{' '}
          <IconExpand
            style={{
              marginLeft: '2px',
              marginRight: '2px',
            }}
          />{' '}
          to upload.
        </Row>
      </Text>
    </Row>

    <Row justify="flex-start">
      <Label mb={2} mt={2}>
        Files
      </Label>
    </Row>

    <WizardFiles
      files={get(formValues, 'files', [])}
      onChangeList={onChangeList}
      onDeleteFile={onDeleteFile}
      onUploadFile={onUploadFile}
      setIsFileUploading={setIsFileUploading}
    />

    {get(wizardErrors, 'fileError', false) && (
      <Row justify="flex-start" mt={2}>
        <Text error>{get(wizardErrors, 'fileError', '')}</Text>
      </Row>
    )}
  </Fragment>
)

export default WizardStepFour
