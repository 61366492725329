import React, { useEffect } from 'react'
import { Text, Row, ShadowedBox } from '@hindawi/ui'
import { Button, Title } from '@hindawi/phenom-ui'

const SubmissionConfirmation = ({ history }) => {
  const journalName = history.location.state

  useEffect(() => {
    if ('hj' in window) {
      hj('trigger', 'SUBMIT_MS_FINISHED')
    } else {
      console.error('HotJar not loaded.')
    }
  })

  return (
    <ShadowedBox center mt={10} pt={8} width={192}>
      <Title level={4} style={{ textAlign: 'center' }}>
        Thank You for Submitting Your Manuscript
      </Title>
      <Row justify="center">
        <Text fontSize="13px" mt={2} secondary>
          Your manuscript has been successfully submitted to{' '}
          <b>{journalName}</b>.
        </Text>
      </Row>

      <Row mb={8} mt={6} pl={4} pr={4}>
        <Text align="center">
          An acknowledgment email will be sent to all authors when our system
          has finished processing the submission - at which point a manuscript
          ID will be assigned, and you will be able to track the manuscript
          status on your dashboard.
        </Text>
      </Row>

      <Row justify="center" mb={4}>
        <Button
          data-test-id="go-to-dashboard"
          onClick={() => history.push('/')}
          type="primary"
          size="large"
        >
          GO TO DASHBOARD
        </Button>
      </Row>
    </ShadowedBox>
  )
}

export default SubmissionConfirmation
