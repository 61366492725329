import moize from 'moize'
import {
  chain,
  cloneDeep,
  get,
  omit,
  isEqual,
  debounce,
  set,
  last,
} from 'lodash'

export const parseError = (e) => last(e.message.split(':')).trim()

export const parseAuthor = (a) =>
  omit({ ...a, ...get(a, 'alias'), ...get(a, 'alias.name') }, [
    '__typename',
    'id',
    'name',
    'alias',
    'user',
    'status',
    'invited',
    'responded',
    'reviewerNumber',
    'invitationSource',
  ])

export const parseFormValues = (values) => {
  const manuscriptId = get(values, 'id', '')
  const sectionId = get(values, 'sectionId', null)
  const meta = omit(get(values, 'meta', {}), [
    '__typename',
    'hasConflictOfInterest',
  ])
  const journalId = get(values, 'journalId', null)
  const sourceJournalId = get(values, 'sourceJournalId', null)
  const sourceJournalManuscriptId = get(
    values,
    'sourceJournalManuscriptId',
    null,
  )
  const linkedSubmissionCustomId = get(values, 'linkedSubmissionCustomId', null)
  const specialIssueId = get(values, 'specialIssueId', null)
  const preprintValue = get(values, 'preprintValue')
  const authors = chain(values)
    .get('authors', [])
    .filter((a) => a.id !== 'unsaved-author')
    .map(parseAuthor)
    .value()

  const files = chain(values)
    .get('files', {})
    .flatMap()
    .map(({ mimeType, originalName, filename, __typename, ...f }) => ({
      ...f,
      name: originalName,
    }))
    .value()

  return {
    manuscriptId,
    autosaveInput: {
      journalId,
      sourceJournalId,
      sourceJournalManuscriptId: sourceJournalManuscriptId || null,
      linkedSubmissionCustomId: linkedSubmissionCustomId || null,
      sectionId,
      specialIssueId,
      preprintValue,
      authors,
      files,
      meta,
    },
  }
}

export const autosaveRequest = ({ values, updateDraft, updateAutosave }) => {
  const variables = parseFormValues(values)
  updateAutosave({
    variables: {
      params: {
        error: null,
        inProgress: true,
        updatedAt: null,
      },
    },
  })
  updateDraft({
    variables,
  }).then((r) => {
    updateAutosave({
      variables: {
        params: {
          error: null,
          inProgress: false,
          updatedAt: Date.now(),
        },
      },
    })
  })
}

const memoizedAutosaveRequest = moize(autosaveRequest, {
  maxSize: 1,
  equals: ({ values: valuesOne }, { values: valuesTwo }) =>
    isEqual(valuesOne, valuesTwo),
})

export const autosaveForm = debounce(memoizedAutosaveRequest, 1000)

export const validateWizard = (step) => (values) => {
  const errors = {}
  const manuscriptFileSize = get(values, 'files.manuscript')[0]?.size

  if (values.isEditing) {
    errors.isEditing = 'An author is being edited.'
  }

  if (step === 3 && manuscriptFileSize === 0) {
    errors.fileError = "Manuscript file shouldn't be empty"
  }

  if (step === 3 && get(values, 'files.manuscript').length === 0) {
    set(errors, 'fileError', 'At least one manuscript is required.')
  }

  return errors
}

export const parseManuscriptFiles = (files = []) =>
  files.reduce(
    (acc, file) => ({
      ...acc,
      [file.type]: [...acc[file.type], file],
    }),
    {
      manuscript: [],
      titlePage: [],
      coverLetter: [],
      supplementary: [],
      figure: [],
      decisionLetter: [],
      reviewComment: [],
    },
  )

export const removeTypename = (inputObject = {}) => {
  const o = cloneDeep(inputObject)
  Object.keys(inputObject).forEach((key) => {
    if (key === '__typename') delete o[key]
    if (o[key] !== null && typeof o[key] === 'object') {
      o[key] = removeTypename(o[key])
    }
  })
  return o
}

export const setInitialValues = (values) => {
  const id = get(values, 'id')
  const submissionId = get(values, 'submissionId')
  const journalId = get(values, 'journalId')
  const sourceJournalId = get(values, 'sourceJournalId')
  const sourceJournalManuscriptId = get(values, 'sourceJournalManuscriptId')
  const linkedSubmissionCustomId = get(values, 'linkedSubmissionCustomId')
  const sectionId = get(values, 'section.id')
  const specialIssueId = get(values, 'specialIssue.id')
  const meta = omit(get(values, 'meta', {}), '__typename')
  const preprintValue = get(values, 'preprintValue')

  const authors = chain(values).get('authors', []).map(removeTypename).value()

  const files = chain(values).get('files', []).map(removeTypename).value()

  const issueType = specialIssueId ? 'specialIssue' : 'regularIssue'

  return {
    id,
    submissionId,
    journalId,
    sectionId,
    specialIssueId,
    authors,
    issueType,
    preprintValue,
    isTransfer: sourceJournalId ? 'yes' : 'no',
    sourceJournalId,
    sourceJournalManuscriptId: sourceJournalManuscriptId || '',
    linkedSubmissionCustomId: linkedSubmissionCustomId || '',
    files: parseManuscriptFiles(files),
    meta: {
      ...meta,
      hasConflictOfInterest: meta.conflictOfInterest ? 'yes' : 'no',
    },
  }
}

export const getQueryParams = (url) =>
  url
    .slice(1, url.length) // remove '?'
    .split('&')
    .reduce((acc, cur) => {
      const [key, value] = cur.split('=')
      return { ...acc, [key]: value }
    }, {})

export const findIdsBySpecialIssue = ({ journals, specialIssue: customId }) => {
  for (const journal of journals) {
    // Search special issue on journal sections
    for (const section of journal.sections) {
      for (const specialIssue of section.specialIssues) {
        if (String(specialIssue.customId) === String(customId))
          return {
            journalId: journal.id,
            sectionId: section.id,
            specialIssueId: specialIssue.id,
          }
      }
    }
    // Search special issue on journal
    for (const specialIssue of journal.specialIssues) {
      if (String(specialIssue.customId) === String(customId))
        return {
          journalId: journal.id,
          sectionId: null,
          specialIssueId: specialIssue.id,
        }
    }
  }
  return {}
}

export const findIdsBySection = ({ journals, section: customId }) => {
  for (const journal of journals) {
    for (const section of journal.sections) {
      if (String(section.customId) === String(customId))
        return {
          journalId: journal.id,
          sectionId: section.id,
          specialIssueId: null,
        }
    }
  }
  return {}
}

export const findIdsByJournal = ({ journals, journal: code }) => {
  for (const journal of journals) {
    if (String(journal.code).toLowerCase() === String(code).toLowerCase())
      return {
        journalId: journal.id,
        sectionId: null,
        specialIssueId: null,
      }
  }
  return {}
}

export const getSubmissionIds = ({
  journals,
  params: { specialIssue, section, journal },
}) => {
  if (specialIssue) return findIdsBySpecialIssue({ journals, specialIssue })
  if (section) return findIdsBySection({ journals, section })
  if (journal) return findIdsByJournal({ journals, journal })
  return {}
}

export function fetchData() {
  return fetch.apply(null, arguments).then((response) => {
    if (!response.ok) {
      // create error object and reject if not a 2xx response code
      const err = new Error('HTTP status code: ' + response.status)
      err.response = response
      err.status = response.status
      throw err
    }
    return response
  })
}

export async function hexDigest(message) {
  const msgUint8 = new TextEncoder().encode(message) // encode as (utf-8) Uint8Array
  const hashBuffer = await crypto.subtle.digest('SHA-256', msgUint8) // hash the message

  const hashArray = Array.from(new Uint8Array(hashBuffer)) // convert buffer to byte array

  return hashArray.map((b) => b.toString(16).padStart(2, '0')).join('')
}
