import React from 'react'
import styled from 'styled-components'
import { useHistory } from 'react-router-dom'
import { ManuscriptCardV2 } from '../..'
import { PlaceholderList } from './PlaceholderList'

const Center = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

// eslint-disable-next-line react/display-name
const renderCard = (history) => (manuscript) => {
  const onClick = () => {
    if (manuscript.status === 'draft') {
      history.push(`/submit/${manuscript.submissionId}/${manuscript.id}`)
    } else {
      history.push(`/details/${manuscript.submissionId}/${manuscript.id}`)
    }
  }

  return (
    <ManuscriptCardV2
      key={manuscript.id}
      manuscript={manuscript}
      onClick={onClick}
    />
  )
}

interface ManuscriptsListProps {
  limit?: number
  loading: boolean
  manuscripts: Array<any>
}

export const ManuscriptsList: React.FC<ManuscriptsListProps> = ({
  limit = 10,
  loading,
  manuscripts,
}) => {
  const history = useHistory()

  if (loading) {
    return <PlaceholderList limit={limit} />
  }

  if (manuscripts.length === 0) {
    return <Center>No manuscripts found.</Center>
  }
  const cards = manuscripts.map(renderCard(history)).slice(0, limit)

  return <div>{cards}</div>
}
