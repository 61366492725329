import React, { Fragment } from 'react'
import styled from 'styled-components'
import { useJournal } from '../../../component-journal-info'
import { get } from 'lodash'
import { Row, Text, Bullet, ActionLink, ValidatedFormField } from '@hindawi/ui'
import { Checkbox, Title } from '@hindawi/phenom-ui'

const validateCheckbox = (value) => (!value ? 'Required' : undefined)
const WizardStepTwo = ({ journal }) => {
  const code = get(journal, 'code', '')
  const apc = get(journal, 'apc', '')
  const {
    links: { authorsGuidelinesLink, apcLink },
  } = useJournal()
  const buildLink = ({ link, journalCode }) =>
    link.replace('{journalCode}', journalCode)
  return (
    <Fragment>
      <Title level={4} style={{ textAlign: 'center' }}>
        2. Pre-Submission Checklist
      </Title>
      <Row mb={8}>
        <Text align="center" mb={2} mt={2} secondary>
          Before continuing please make sure you have reviewed the items on the
          list below:
        </Text>
      </Row>
      <Row alignItems="center" justify="flex-start" mb={2}>
        <Bullet />
        <Text bullet display="inline" ml={4}>
          I am aware that accepted manuscripts are subject to an
          <ActionLink
            display="inline"
            pl={1}
            to={buildLink({ link: apcLink, journalCode: code })}
          >
            Article Processing Charge of ${apc}
          </ActionLink>
          , which is payable upon receipt of invoice, billed upon acceptance of
          submission for publication.
        </Text>
      </Row>

      <Row alignItems="center" justify="flex-start" mb={2}>
        <Bullet />

        <Text bullet display="inline" ml={4}>
          All co-authors have read and agreed on the current version of this
          manuscript.
        </Text>
      </Row>

      <Row alignItems="center" justify="flex-start" mb={2}>
        <Bullet />

        <Text bullet display="inline" ml={4}>
          I have the email addresses of all co-authors of the manuscript.
        </Text>
      </Row>

      <Row alignItems="center" justify="flex-start" mb={2}>
        <Bullet />

        <Text bullet display="inline" ml={4}>
          I confirm the main manuscript file is in Microsoft Word or Adobe PDF
          format with the tables and figures integrated in the manuscript body.
        </Text>
      </Row>

      <Row alignItems="center" justify="flex-start" mb={2}>
        <Bullet />

        <Text bullet display="inline" ml={4}>
          I have all additional electronic files of supplementary materials
          (e.g. datasets, images, audio, video) ready.
        </Text>
      </Row>

      <Row alignItems="center" justify="flex-start" mb={2}>
        <Bullet />

        <Text bullet display="inline" ml={4}>
          I am aware that an
          <ActionLink display="inline" pl={1} pr={1} to="https://orcid.org/">
            ORCID
          </ActionLink>
          is required for the corresponding author before the article can be
          published (if accepted). The ORCID should be added via your user
          account.
        </Text>
      </Row>

      <Row alignItems="center" justify="flex-start" mb={2}>
        <Bullet />

        <Text bullet display="inline" ml={4}>
          I have read the journal’s
          <ActionLink
            display="inline"
            pl={1}
            pr={1}
            to={buildLink({ link: authorsGuidelinesLink, journalCode: code })}
          >
            Author Submission Guidelines.
          </ActionLink>
        </Text>
      </Row>

      <Row alignItems="center" justify="center" mt={12}>
        <div>
          <ValidatedFormField
            component={CustomCheckbox}
            name="meta.agreeTc"
            validate={[validateCheckbox]}
          />
        </div>
      </Row>
    </Fragment>
  )
}

const CustomCheckbox = (input) => (
  <RootCheckboxWrapper data-test-id="agree-checkbox">
    <Checkbox checked={input.value} {...input}>
      I have reviewed and understood all of the above.
    </Checkbox>
  </RootCheckboxWrapper>
)

export default WizardStepTwo

// #region styled-components
const RootCheckboxWrapper = styled.div.attrs((props) => ({
  className: 'custom-checkbox',
}))`
  + div[role='alert'] {
    margin-top: 0;
  }
  & label {
    margin-bottom: 4px;
    & span {
      color: #4f4f4f;
      font-family: 'Nunito';
      font-size: 14px;
    }
  }
`
// #endregion
