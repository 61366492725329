import { Button } from '@hindawi/phenom-ui'
import React from 'react'
import styled from 'styled-components'

const SyledButton = styled(Button)`
  margin-right: 16px;
`

export const TransferManuscript = ({ label = 'Transfer Manuscript' }) => {
  const transferManuscriptUrl = process.env.TRANSFER_MANUSCRIPT_URL as string

  const transferManuscript = () => {
    window.location.assign(transferManuscriptUrl)
  }

  return (
    <SyledButton
      data-test-id="transfer-manuscript"
      onClick={() => transferManuscript()}
    >
      {label}
    </SyledButton>
  )
}
