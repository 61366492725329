import React, { Fragment, useState, useEffect } from 'react'
import { useCurrentUser } from '../../../component-authentication/client'
import { get } from 'lodash'
import { Formik } from 'formik'
import { Redirect } from 'react-router-dom'
import { useLazyQuery } from 'react-apollo'
import styled from 'styled-components'
import { withModal } from '../../../component-modal'
import { compose, withHandlers, withProps } from 'recompose'
import { withGQL as withFilesGQL } from '../../../component-files/client'
import { MultiAction, withSteps, withFetching } from '@hindawi/ui'
import withSubmissionGQL, { queries } from '../graphql'
import {
  autosaveForm as autoSaveFormHandler,
  setInitialValues,
  validateWizard,
  parseError,
  fetchData,
  hexDigest,
} from '../utils'
import { SubmissionStatement, WizardButtons, wizardSteps } from '../components'
import { Col, ProgressIndicator, Row, Spinner } from '@hindawi/phenom-ui'
import { ROLES } from '@shared/ui/constants'

const manuscriptIdPath = 'params.manuscriptId'

const replaceUnderscoreWithDash = (str) => str.replace(/_/g, '-')

const underscoredFields = [
  'X_Amz_Algorithm',
  'X_Amz_Credential',
  'X_Amz_Security_Token',
  'X_Amz_Signature',
  'X_Amz_Date',
]

// handlers start
const onUploadFile =
  ({ match, uploadFile, getUploadSignedUrl }) =>
  (file, { type, push, setFetching, setError }) => {
    const manuscriptId = get(match, manuscriptIdPath, '')

    const fileInput = {
      type,
      size: file.size,
      name: file.name,
      mimetype: file.type,
    }

    setFetching(true)

    getUploadSignedUrl(manuscriptId)
      .then(({ url, fields }) => {
        const formData = new FormData()
        formData.append('Content-Type', file.type)
        Object.entries(fields).forEach(([k, v]) => {
          if (underscoredFields.includes(k)) {
            formData.append(replaceUnderscoreWithDash(k), v)
          } else {
            formData.append(k, v)
          }
        })
        formData.delete('__typename')
        // must be last entry
        formData.append('file', file)

        const requestOptions = {
          method: 'POST',
          body: formData,
        }

        fetchData(url, requestOptions)
          .then((response) => {
            uploadFile({
              entityId: manuscriptId,
              providerKey: fields.key,
              fileInput,
            })
              .then((uploadedFile) => {
                setFetching(false)
                push(uploadedFile)
              })
              .catch((e) => {
                setFetching(false)
                setError(parseError(e))
              })
          })
          .catch((e) => {
            setFetching(false)
            setError(parseError(e))
          })
      })
      .catch((e) => {
        setFetching(false)
        setError(parseError(e))
      })
  }

const onDeleteFile =
  ({ deleteFile }) =>
  (file, { index, remove, setError, setFetching }) => {
    setFetching(true)
    deleteFile(file.id)
      .then(() => {
        setFetching(false)
        remove(index)
      })
      .catch((e) => {
        setFetching(false)
        setError(parseError(e))
      })
  }

const onChangeList =
  ({ updateManuscriptFile }) =>
  ({ fileId, sourceProps, toListName: type, destinationProps }) => {
    updateManuscriptFile({
      variables: {
        fileId,
        type,
      },
    })
      .then((r) => {
        const file = r.data.updateManuscriptFile
        sourceProps.remove(sourceProps.index)
        destinationProps.push(file)
      })
      .catch((e) => {
        destinationProps.setError(parseError(e))
      })
  }

const onDeleteAuthor =
  ({ match, removeAuthor }) =>
  ({ values, setFieldValue }) =>
  (
    { id: authorTeamMemberId },
    { setError, clearError, setEditMode, setFetching, setWizardEditMode },
  ) => {
    const manuscriptId = get(match, manuscriptIdPath, '')
    clearError()
    setFetching(true)
    removeAuthor({
      variables: { manuscriptId, authorTeamMemberId },
    })
      .then((r) => {
        setFetching(false)
        setEditMode(false)
        setFieldValue('authors', r.data.removeAuthorFromManuscript)
      })
      .catch((e) => {
        setFetching(false)
        setError(parseError(e))
      })
  }

const onEditAuthor =
  ({ match, editAuthor }) =>
  ({ values, setFieldValue }) =>
  (
    { id: authorTeamMemberId, ...authorInput },
    { setError, clearError, setEditMode, setFetching, setWizardEditMode },
  ) => {
    const manuscriptId = get(match, manuscriptIdPath, '')
    clearError()
    setFetching(true)
    editAuthor({
      variables: { manuscriptId, authorInput, authorTeamMemberId },
    })
      .then((r) => {
        setFetching(false)
        setWizardEditMode(false)
        setFieldValue('authors', r.data.editAuthorFromManuscript)
      })
      .catch((e) => {
        setFetching(false)
        setError(parseError(e))
      })
  }

const onSaveAuthor =
  ({ addAuthorToManuscript, match }) =>
  ({ values, setFieldValue }) =>
  (
    { id, ...authorInput },
    {
      index,
      formFns,
      setError,
      clearError,
      setEditMode,
      setFetching,
      setWizardEditMode,
    },
  ) => {
    clearError()
    setFetching(true)
    const manuscriptId = get(match, manuscriptIdPath)
    addAuthorToManuscript({
      variables: { manuscriptId, authorInput },
    })
      .then((r) => {
        setFetching(false)
        setWizardEditMode(false)
        setFieldValue('authors', r.data.addAuthorToManuscript)
      })
      .catch((e) => {
        setFetching(false)
        setError(parseError(e))
      })
  }

const autosaveForm =
  ({ match, updateDraft, updateAutosave }) =>
  (values) => {
    autoSaveFormHandler({
      values,
      updateDraft,
      updateAutosave,
    })
  }

const gaEventTrigger = async ({ values, journal }) => {
  const { apc, name: parentJournal, sections, specialIssues } = journal
  const { authors, specialIssueId, submissionId, sectionId } = values

  const email =
    authors.find((author) => author.isSubmitting)?.alias?.email || ''
  const isSI = !!specialIssueId

  const findSection = () =>
    sections.find((section) => section.id === sectionId) ||
    (isSI &&
      sections.find((section) =>
        section.specialIssues.find((si) => si.id === specialIssueId),
      ))

  const findSpecialIssue = (section) => {
    if (!isSI) return null
    return (
      (!section && specialIssues.find((si) => si.id === specialIssueId)) ||
      (section && section.specialIssues.find((si) => si.id === specialIssueId))
    )
  }

  const section = findSection()
  const si = findSpecialIssue(section)
  const specialIssueName = si?.name || ''

  const hashedEmail = await hexDigest(email)

  return {
    event: 'manuscript_submission_complete',
    ecommerce: {
      transaction_id: submissionId,
      value: apc,
      section: section?.name || '',
      currency: 'USD',
      submission_id: submissionId,
      parentJournal,
      discipline: '',
      regular_issue_section: sectionId ? section?.name : '',
      special_issue: isSI,
      special_issue_name: isSI ? specialIssueName : '',
      hashed_email: hashedEmail,
      items: [
        {
          item_name: parentJournal,
          item_id: submissionId,
          item_category: '',
        },
      ],
    },
  }
}

const onSubmit =
  ({
    step,
    history,
    editMode,
    nextStep,
    showModal,
    setFetching,
    submitManuscript,
    editManuscript,
    journal,
    tracking,
  }) =>
  (values, formikBag) => {
    if (step !== wizardSteps.length - 1) {
      if (step === 2 && get(values, 'authors', []).length === 0) {
        formikBag.setFieldError('authors', 'At least one author is required.')
      } else {
        nextStep()
        formikBag.resetForm(values)
      }
    } else if (editMode) {
      editManuscript({ variables: { values } })
      history.goBack()
    } else if (!editMode) {
      showModal({
        title:
          'By submitting the manuscript, you agree to the following statements:',
        content: SubmissionStatement,
        confirmText: 'AGREE & SUBMIT',
        cancelText: 'BACK TO SUBMISSION',
        onConfirm: ({ hideModal, setFetching, setError }) => {
          setFetching(true)
          submitManuscript({
            variables: { values },
          })
            .then(async (r) => {
              setFetching(false)
              hideModal()
              const gaEventSubmissionComplete = await gaEventTrigger({
                values,
                journal,
              })
              tracking.trackEvent(gaEventSubmissionComplete)
              history.push('/confirmation-page', journal.name)
            })
            .catch((e) => {
              setFetching(false)
              setError(parseError(e))
            })
        },
      })
    }
  }
// handlers end
const CheckSubmissionSystemPermissions = (
  manuscriptStatus,
  manuscriptSubmissionSystem,
) => {
  const { role } = useCurrentUser()
  const [userIsAdminOrEA, setUserIsAdminOrEA] = useState(false)

  if (manuscriptSubmissionSystem === 'ReX' && !userIsAdminOrEA) {
    setUserIsAdminOrEA([ROLES.ADMIN, ROLES.EA].includes(role))
  }
  return (
    manuscriptSubmissionSystem === 'ReX' &&
    manuscriptStatus === 'draft' &&
    userIsAdminOrEA
  )
}
const defineProps = ({ data }) => {
  const { manuscript } = data
  const manuscriptStatus = get(manuscript, 'status')
  const manuscriptSubmissionSystem = get(
    manuscript,
    'submissionSystem',
    'Phenom',
  )
  const submissionSystemCheck = CheckSubmissionSystemPermissions(
    manuscriptStatus,
    manuscriptSubmissionSystem,
  )
  const activeJournals = get(data, 'getActiveJournals', [])
  const journal = activeJournals.find(
    (journal) => journal.id === manuscript.journalId,
  )
  const shouldDisableEditJournal = journal?.acceptsPhenomSubmissions === false
  // PPBK-5381: this should allow re-submission on closed special issues
  // after they are return to draft
  if (
    manuscriptStatus === 'draft' &&
    manuscript.specialIssue &&
    manuscript.submittedDate?.length > 0 &&
    journal &&
    journal.specialIssues &&
    journal.specialIssues.findIndex(
      (x) => x.id === manuscript.specialIssue.id,
    ) === -1
  ) {
    journal.specialIssues.push(manuscript.specialIssue)
  }

  return {
    initialValues: setInitialValues(manuscript),
    editMode: get(manuscript, 'status', 'draft') !== 'draft',
    manuscriptStatus,
    permissionError:
      manuscriptStatus === 'accepted' ||
      manuscriptStatus === 'rejected' ||
      submissionSystemCheck,
    journal,
    isAuthorEmailEditable: get(manuscript, 'isAuthorEmailEditable'),
    activeJournals,
    shouldDisableEditJournal,
  }
}

const Wizard = ({
  step,
  theme,
  journal,
  activeJournals,
  shouldDisableEditJournal,
  history,
  onSubmit,
  prevStep,
  showModal,
  isFetching,
  autosaveForm,
  onEditAuthor,
  onSaveAuthor,
  initialValues,
  onDeleteAuthor,
  permissionError,
  data: { loading },
  editMode,
  isAuthorEmailEditable,
  ...rest
}) => {
  const [isFetchingEditorialModel, setIsEditorialModelFetching] =
    useState(false)
  const [isFileUploading, setIsFileUploading] = useState(false)
  const [submissionEditorialModel, setSubmissionEditorialModel] = useState()

  const [getSubmissionEditorialMapping, { data }] = useLazyQuery(
    queries.getSubmissionEditorialMapping,
    {
      fetchPolicy: 'network-only',
    },
  )

  const submissionEditorialMapping = get(data, 'getSubmissionEditorialMapping')
  useEffect(() => {
    if (!initialValues.submissionId) return
    getSubmissionEditorialMapping({
      variables: { submissionId: initialValues.submissionId },
    })
  }, [initialValues.id])

  useEffect(() => {
    if (submissionEditorialMapping)
      setSubmissionEditorialModel(
        submissionEditorialMapping.submissionEditorialModel,
      )
  }, [submissionEditorialMapping])

  if (permissionError) {
    return <Redirect to="/404" />
  }
  if (loading) {
    return (
      <Row align="middle" justify="center" style={{ height: '100vh' }}>
        <Col style={{ marginTop: '-72px' }}>
          <Spinner />
        </Col>
      </Row>
    )
  }

  return (
    <Fragment>
      <ProgressIndicatorWrapper>
        <ProgressIndicator
          current={step}
          steps={wizardSteps}
          className="submission-progress-indicator"
        />
      </ProgressIndicatorWrapper>
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validate={validateWizard(step)}
      >
        {({ errors, handleSubmit, setFieldValue, values, setValues }) => (
          <Root isFirst={step === 0}>
            {autosaveForm(values)}

            {React.createElement(wizardSteps[step].component, {
              shouldDisableEditJournal,
              journal,
              activeJournals,
              setIsEditorialModelFetching,
              setValues,
              setFieldValue,
              submissionEditorialModel,
              setSubmissionEditorialModel,
              formValues: values,
              wizardErrors: errors,
              onEditAuthor: onEditAuthor({ values, setFieldValue }),
              onSaveAuthor: onSaveAuthor({ values, setFieldValue }),
              onDeleteAuthor: onDeleteAuthor({ values, setFieldValue }),
              setIsFileUploading,
              isAuthorEmailEditable,
              ...rest,
            })}

            <WizardButtons
              editMode={editMode}
              handleSubmit={handleSubmit}
              hasArticleType={!!values.meta.articleTypeId}
              hasSubmissionEditorialModel={!!submissionEditorialModel}
              history={history}
              isFetching={isFetching}
              isFetchingEditorialModel={isFetchingEditorialModel}
              isFileUploading={isFileUploading}
              isFirst={step === 0}
              isLast={step === wizardSteps.length - 1}
              journal={journal}
              prevStep={prevStep}
            />
          </Root>
        )}
      </Formik>
    </Fragment>
  )
}
// #region compose
export default compose(
  withSteps,
  withFetching,
  withSubmissionGQL,
  withFilesGQL(),
  withModal({
    component: MultiAction,
    modalKey: 'submission-wizard',
  }),
  withProps(defineProps),
  withHandlers({
    // autosave
    autosaveForm,
    onUploadFile,
    onDeleteFile,
    onChangeList,
    onDeleteAuthor,
    onEditAuthor,
    onSaveAuthor,
    onSubmit,
  }),
)(Wizard)
// #endregion

// #region styles
const Root = styled.div`
  background-color: white;
  border-radius: 4px;
  box-shadow: 0 1px 2px 1px #dbdbdb;
  margin: 0 auto;
  margin-top: calc(4px * 10);
  margin-bottom: calc(4px * 10);

  padding: calc(4px * 10);

  width: calc((4px) * (${({ isFirst }) => (isFirst ? 148 : 280)}));
`

const ProgressIndicatorWrapper = styled.div`
  margin: calc(16px) auto 0;
  width: calc(640px);
`

// #endregion
