import { Col, Row, Tag } from '@hindawi/phenom-ui'
import React from 'react'
import { useHistory } from 'react-router-dom'
import { RevisionDeadlineMessage } from '../../../../../component-peer-review/client/components/manuscriptDetails/RevisionDeadlinesMessage'
import { getStatusCategory, getStatusPriority } from '../utils'
import {
  AcademicEditorCell,
  ArticleTypeCell,
  AuthorsCell,
  Card,
  Content,
  CustomIdCell,
  JournalCell,
  LeftLine,
  ReviewerReportCell,
  StatusCell,
  DateCell,
  TitleCell,
} from './components'
import { useGetTitleAspects } from './hooks'
import './styles.css'
import { ManuscriptCardProps } from './types'

export const ManuscriptCard: React.FC<ManuscriptCardProps> = ({
  manuscript: {
    id,
    submissionId,
    customId,
    meta: { title },
    journal,
    articleType = {},
    version,
    visibleStatus,
    status,
    academicEditor,
    authors = [],
    submittedDate,
    reviewers = [],
    editors = [],
    peerReviewModel = {},
    currentUserRole,
    created,
    sourceJournalId,
    commissionInvitationId,
    peerReviewEditorialMapping = {
      peerReviewEditorialModel: {
        triageEditor: {
          label: null,
        },
        academicEditor: {
          label: null,
        },
      },
    },
    revisionDeadlineDate,
    editorialAssistant,
  },
  propagationCallback,
}) => {
  reviewers = reviewers || []
  editors = editors || []
  const history = useHistory()

  const statusPriority = getStatusPriority(status)
  const statusCategory = getStatusCategory(status)

  const onClickAction = () => {
    if (status === 'draft') {
      history.push(`/submit/${submissionId}/${id}`)
    } else {
      history.push(`/details/${submissionId}/${id}`)
    }
  }
  const onClick = () => {
    const selection = window.getSelection()
      ? window.getSelection()?.toString()
      : false
    if (!selection) {
      onClickAction()
    }
    if (propagationCallback) {
      propagationCallback()
    }
  }

  const {
    fontSize: titleFontSize,
    rows: titleEllipsisRows,
    lineHeight: titleLineHeight,
  } = useGetTitleAspects()

  return (
    <div className="manuscript-container">
      <LeftLine statusColor={statusCategory} old={false} />
      <Card className="manuscript-card" onClick={onClick}>
        <Content className="manuscript-content">
          <Row gutter={[16, 12]} className="custom-id-status-row">
            <Col onClick={(event) => event.stopPropagation()}>
              {customId && <CustomIdCell customId={customId} />}
            </Col>
            <Col flex="auto">
              {currentUserRole !== 'reviewer' && commissionInvitationId && (
                <Tag
                  label="commissioned"
                  outline
                  data-test-id="commissioned-tag"
                  style={{ marginLeft: '8px' }}
                />
              )}
              {sourceJournalId && (
                <Tag
                  label="transferred"
                  outline
                  data-test-id="transferred-tag"
                />
              )}
            </Col>
            {revisionDeadlineDate && (
              <Col data-test-id="ms-revision-deadline-date">
                <RevisionDeadlineMessage
                  editorialAssistant={editorialAssistant}
                  currentUserRole={currentUserRole}
                  revisionDeadlineDate={revisionDeadlineDate}
                />
              </Col>
            )}
            <Col>
              <StatusCell
                visibleStatus={visibleStatus}
                statusPriority={statusPriority}
                version={version}
              />
            </Col>
          </Row>
          <Col className="manuscript-subcontent">
            <Row>
              <Col className="col-oh">
                <TitleCell
                  fontSize={titleFontSize}
                  lineHeight={titleLineHeight}
                  title={title || 'No Title'}
                  ellipsisRows={titleEllipsisRows}
                />
              </Col>
            </Row>
            <Row className="author-cell" gutter={[16, 12]}>
              <AuthorsCell authors={authors} />
            </Row>
            <div className="manuscript-card-article-type-section">
              <Col className="manuscript-card-article-type" flex="auto">
                <ArticleTypeCell articleType={articleType} />
              </Col>
              <Col>
                <AcademicEditorCell
                  academicEditor={academicEditor}
                  academicEditorLabel={
                    peerReviewEditorialMapping?.peerReviewEditorialModel
                      ?.academicEditor.label ||
                    peerReviewModel?.academicEditorLabel
                  }
                />
              </Col>
              <Col flex="auto">
                {currentUserRole !== 'author' &&
                  currentUserRole !== 'reviewer' && (
                    <ReviewerReportCell reviewers={reviewers} />
                  )}
              </Col>
            </div>
          </Col>
        </Content>
        <div className="manuscript-footer">
          <Col flex="auto">{journal && <JournalCell journal={journal} />}</Col>
          <Col>
            {status === 'draft' ? (
              <DateCell date={created} label={'Created on'} />
            ) : (
              <DateCell date={submittedDate} label={'Submitted on'} />
            )}
          </Col>
        </div>
      </Card>
    </div>
  )
}
