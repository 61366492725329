import React from 'react'
import styled from 'styled-components'
import { Card, Skeleton } from '@hindawi/phenom-ui'

interface PlaceholderListProps {
  limit?: number
}

const SkeletonCard = styled(Card)`
  .ant-card-body > div {
    width: 100%;
  }

  .ant-skeleton-input {
    height: 16px;
    line-height: 16px;
  }
`

const SkeletonRow = styled.div`
  display: flex;
  justify-content: space-between;

  & > div:first-child {
    width: 100%;
  }
`

const PlaceholderListWrapper = styled.div`
  & > div {
    margin-bottom: 8px;
  }
`

export const PlaceholderList: React.FC<PlaceholderListProps> = ({
  limit = 10,
}) => {
  const Placeholder = () => (
    <SkeletonCard>
      <SkeletonRow>
        <Skeleton.Input active style={{ width: '30%' }} />
        <Skeleton.Button active size="small" style={{ width: '135px' }} />
      </SkeletonRow>
      <Skeleton.Input active style={{ width: '15%' }} />
      <Skeleton.Input active style={{ width: '70%' }} />
      <Skeleton.Input active style={{ width: '20%' }} />
    </SkeletonCard>
  )

  return (
    <PlaceholderListWrapper>
      {[...new Array(limit)]
        .map((_, index) => index)
        .map((item) => (
          <Placeholder key={item} />
        ))}
    </PlaceholderListWrapper>
  )
}
