import React, { Fragment } from 'react'
import { useJournal } from '../../../component-journal-info'
import { ActionLink, Text, Row } from '@hindawi/ui'

const SubmissionStatement = () => {
  const {
    links: { ethicsLink, termsLink, privacyLink },
  } = useJournal()
  return (
    <Fragment>
      <Row mb={2} mt={4}>
        <Text lineHeight="1">
          This manuscript is not currently submitted to or under consideration
          in any other journals.
        </Text>
      </Row>

      <Row mb={2}>
        <Text display="inline">
          The manuscript complies with all relevant{' '}
          <ActionLink display="inline" to={ethicsLink}>
            publication and research ethics policies.
          </ActionLink>
        </Text>
      </Row>

      <Row mb={4}>
        <Text display="inline">
          You have read and understood the{' '}
          <ActionLink display="inline" to={termsLink}>
            terms of service
          </ActionLink>{' '}
          and{' '}
          <ActionLink display="inline" to={privacyLink}>
            privacy policy
          </ActionLink>{' '}
          under which this manuscript will be processed.
        </Text>
      </Row>
    </Fragment>
  )
}

export default SubmissionStatement
