import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { compose, setDisplayName, withHandlers } from 'recompose'

import { IconButton, Text } from '../../'
import { Button, IconClose, Title } from '@hindawi/phenom-ui'

const SingleActionModal = ({
  error,
  title,
  content,
  onClick,
  subtitle,
  confirmText,
  onConfirm,
  renderContent,
  hasCloseIcon,
  ...rest
}) => (
  <Root {...rest}>
    {hasCloseIcon && (
      <IconButton
        icon={IconClose}
        onClick={onClick}
        right={8}
        secondary
        top={12}
      />
    )}
    <IconButton
      error={error}
      fontIcon={error ? 'removeIcon' : 'saveIcon'}
      primary={!error}
      size={4}
    />
    {title && (
      <Title level={4} style={{ textAlign: 'center' }}>
        {title}
      </Title>
    )}
    {subtitle && <Text secondary>{subtitle}</Text>}
    {renderContent()}
    <Button data-test-id="modal-confirm" onClick={onClick} primary>
      {confirmText}
    </Button>
  </Root>
)

export default compose(
  withHandlers({
    onClick:
      ({ onCancel, onConfirm, hideModal }) =>
      () => {
        if (typeof onCancel === 'function') {
          onCancel()
        }
        if (typeof onConfirm === 'function') {
          onConfirm()
        }
        hideModal()
      },
    renderContent:
      ({ content, ...props }) =>
      () => {
        if (!content) return null
        if (typeof content === 'object') {
          return content
        } else if (typeof content === 'function') {
          return content(props)
        }
        return <Text dangerouslySetInnerHTML={{ __html: content }} mb={2} />
      },
  }),
  setDisplayName('SingleActionModal'),
)(SingleActionModal)

// #region styles
const Root = styled.div`
  align-items: center;
  border: 1px solid transparent;
  border-radius: 4px;
  background: #ecf0f3;
  box-shadow: 0 1px 2px 1px #dbdbdb;
  display: flex;
  flex-direction: column;
  position: relative;
  padding: calc(4px * 10);
  width: 320px;

  ${Button} {
    margin-top: calc(4px * 2);
  }
`

SingleActionModal.propTypes = {
  /** Title that will be showed on the card. */
  title: PropTypes.string,
  /** Subtitle that will be showed on the card. */
  subtitle: PropTypes.string,
  /** Callback function fired when confirm confirmation card. */
  onConfirm: PropTypes.func,
  /** The text you want to see on the button when someone submit the report. */
  confirmText: PropTypes.string,
  /** If true success icon is replaced with error icon.  */
  error: PropTypes.bool,
  /** If true close icon is displayed.  */
  hasCloseIcon: PropTypes.bool,
}

SingleActionModal.defaultProps = {
  title: undefined,
  subtitle: undefined,
  onConfirm: undefined,
  confirmText: 'OK',
  error: undefined,
  hasCloseIcon: true,
}
// #endregion
