import React, { useEffect } from 'react'
import { useQuery, useMutation } from 'react-apollo'
import { get } from 'lodash'
import { Spinner } from '@hindawi/phenom-ui'

import { mutations, queries } from '../graphql'
import { getQueryParams, getSubmissionIds } from '../utils'

const SubmissionFromURL = ({ history, match, location }) => {
  const { data } = useQuery(queries.getActiveJournals)
  const [createDraft] = useMutation(mutations.createDraftManuscript)

  const activeJournals = get(data, 'getActiveJournals', [])
  useEffect(() => {
    if (activeJournals.length > 0) {
      const params = getQueryParams(location.search)
      const { journalId, sectionId, specialIssueId } = getSubmissionIds({
        params,
        journals: activeJournals,
      })
      createDraft({
        variables: { input: { journalId, sectionId, specialIssueId } },
      })
        .then(({ data: { createDraftManuscript } }) => {
          history.replace(
            `/submit/${createDraftManuscript.submissionId}/${createDraftManuscript.id}`,
          )
        })
        .catch(console.error)
    }
  }, [activeJournals, createDraft, history, match, location.search])
  return <Spinner />
}

export default SubmissionFromURL
