import { Button } from '@hindawi/phenom-ui'
import React from 'react'

import { useMutation } from 'react-apollo'
import { useHistory } from 'react-router-dom'
import { mutations } from '../graphql'

export const SubmitDraft = ({ label = 'New Submission' }) => {
  const history = useHistory()

  const [createDraft] = useMutation(mutations.createDraftManuscript)

  const onSubmit = async () => {
    const draftManuscript = await createDraft({
      fetchPolicy: 'no-cache',
      variables: { input: {} },
    }).then((response) => response.data.createDraftManuscript)
    history.push(
      `/submit/${draftManuscript.submissionId}/${draftManuscript.id}`,
    )
  }

  return (
    <Button data-test-id="manuscript-submit" onClick={onSubmit}>
      {label}
    </Button>
  )
}
