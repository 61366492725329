import React, { useState, useEffect, Fragment } from 'react'
import {
  Row,
  Col,
  Typography,
  IconCaretDown,
  IconCaretUp,
  Space,
  Tooltip,
  Tag,
} from '@hindawi/phenom-ui'
import { StatusTag, DateParser } from '@hindawi/ui'
import { AuthorTagList } from '@shared/ui'
import { Card, Content, Footer, Label } from './styles'
import { getStatusCategory } from '../utils'
import { RevisionDeadlineMessage } from '../../../../../component-peer-review/client/components/manuscriptDetails/RevisionDeadlinesMessage'
import { Author } from '@shared/ui/types'
import { PeerReviewEditorialMappingT } from '../EditorialManuscriptCard/types'
const { Text, Title, Link } = Typography

type MemberT = {
  id: string
  status: string
  alias: {
    email: string
    name: {
      givenNames: string
      surname: string
    }
  }
} | null

enum WaiverType {
  WAIVER_CODE = 'waiverCode',
  APC_OVERRIDE = 'apcOverride',
}

type WaiverApplied = {
  type: WaiverType
  reason: string | null
  otherReason: string | null
} | null

const stopPropagation = (e) => e.stopPropagation()
interface ManuscriptCardProps {
  manuscript: {
    customId: string
    meta: {
      title: string | undefined
    }
    journal?: {
      name: string | undefined
    }
    articleType?: {
      name: string | undefined
    }
    sourceJournalId?: string | undefined
    commissionInvitationId?: string | undefined
    version: string
    visibleStatus: string
    status: string
    updated: string
    submittedDate: string
    academicEditor: MemberT
    editorialAssistant: MemberT
    triageEditor: MemberT
    authors: Author[]
    specialIssue: { name: string }
    section: { name: string }
    reviewers: { status: string }[]
    editors: { status: string }[]
    expanded: boolean
    peerReviewModel?: {
      triageEditorLabel: string | undefined
      academicEditorLabel: string | undefined
    }
    peerReviewEditorialMapping?: PeerReviewEditorialMappingT
    revisionDeadlineDate?: Date
    currentUserRole?: string
    waiverApplied: WaiverApplied
  }
  onClick: () => void
}

const renderEditorsCells = ({
  triageEditor,
  triageEditorLabel,
  academicEditor,
  academicEditorLabel,
}) =>
  [
    {
      title: triageEditorLabel,
      data: triageEditor,
    },
    {
      title: academicEditorLabel,
      data: academicEditor,
    },
  ].map(({ title, data }) => (
    <Col key={title} onClick={stopPropagation} span={4}>
      <Label>{title}</Label>
      <div>
        {!data ? (
          'Unassigned'
        ) : (
          <Tooltip
            copyContent={data.alias.email}
            showCopyContent
            replaceTooltipText="Editor name"
          >
            <Link>
              {data.alias.name.givenNames} {data.alias.name.surname}
            </Link>
          </Tooltip>
        )}
      </div>
    </Col>
  ))

const renderShowMore = ({ section, reviewers, editors, specialIssue }) => (
  <>
    <Row gutter={[16, 12]}>
      {section && (
        <Col xl={4} xxl={8}>
          <Label>Section</Label>
          <Text ellipsis>{section.name}</Text>
        </Col>
      )}
      <Col xl={10} xxl={8}>
        <Label>Editor Invites</Label>
        <div>
          <Space size={4}>
            <Label>{editors.length}</Label>
            <span>Invited,</span>
            <Label>
              {
                editors.filter((editor) =>
                  ['accepted', 'active'].includes(editor.status),
                ).length
              }
            </Label>
            <span>Agreed,</span>
            <Label>
              {editors.filter((editor) => editor.status === 'declined').length}
            </Label>
            <span>Declined,</span>
            <Label>
              {editors.filter((editor) => editor.status === 'pending').length}
            </Label>
            <span>Pending</span>
          </Space>
        </div>
      </Col>
      <Col xl={10} xxl={8}>
        <Label>Reviewer Reports</Label>
        <div>
          <Space size={4}>
            <Label>{reviewers.length}</Label>
            <span>Invited,</span>
            <Label>
              {
                reviewers.filter((reviewer) => reviewer.status === 'accepted')
                  .length
              }
            </Label>
            <span>Agreed,</span>
            <Label>
              {
                reviewers.filter((reviewer) => reviewer.status === 'declined')
                  .length
              }
            </Label>
            <span>Declined,</span>
            <Label>
              {
                reviewers.filter((reviewer) => reviewer.status === 'submitted')
                  .length
              }
            </Label>
            <span>Submitted</span>
          </Space>
        </div>
      </Col>
    </Row>
    {specialIssue && (
      <Row gutter={[16, 12]}>
        <Col>
          <Label>Special Issue</Label>
          <Text ellipsis>{specialIssue.name}</Text>
        </Col>
      </Row>
    )}
  </>
)

export const ManuscriptCard: React.FC<ManuscriptCardProps> = ({
  manuscript: {
    customId,
    meta: { title = 'No Title' },
    journal = {},
    articleType = {},
    sourceJournalId,
    commissionInvitationId,
    version,
    visibleStatus,
    status,
    academicEditor,
    triageEditor,
    authors = [],
    submittedDate,
    updated,
    specialIssue,
    section,
    reviewers = [],
    editors = [],
    expanded,
    peerReviewModel = {},
    revisionDeadlineDate,
    currentUserRole,
    editorialAssistant,
    waiverApplied = {
      type: WaiverType.WAIVER_CODE,
      otherReason: null,
      reason: null,
    },
    peerReviewEditorialMapping = {
      peerReviewEditorialModel: {
        triageEditor: {
          label: null,
        },
        academicEditor: {
          label: null,
        },
      },
    },
  },
  onClick,
}) => {
  const [showMore, setShowMore] = useState(expanded)

  useEffect(() => {
    setShowMore(expanded)
  }, [expanded])

  reviewers = reviewers || []
  editors = editors || []

  return (
    <Card onClick={onClick} statusColor={getStatusCategory(status)} old={false}>
      <Content>
        <Row gutter={[16, 12]}>
          <Col mr={16} flex="none" onClick={stopPropagation}>
            <Label>ID</Label>
            <Tooltip
              copyContent={customId}
              showCopyContent
              replaceTooltipText="Custom ID"
            >
              <Link>{customId}</Link>
            </Tooltip>
            {commissionInvitationId && (
              <Tag
                label="commissioned"
                style={{ marginLeft: '8px' }}
                outline
                data-test-id="commissioned-tag"
              />
            )}
            {sourceJournalId && (
              <Tag
                label="transferred"
                style={{ marginLeft: '8px' }}
                outline
                data-test-id="transferred-tag"
              />
            )}
            {waiverApplied && (
              <Tag
                label={
                  waiverApplied?.type === WaiverType.WAIVER_CODE
                    ? 'Waiver applied'
                    : 'APC Override Requested'
                }
                outline
                priority="positive"
                style={{ marginLeft: '8px' }}
                data-test-id="goa-tag"
              />
            )}
          </Col>
          <Col className="col-oh" flex="auto">
            <Label>
              <Title
                data-test-id="manuscript-title"
                ellipsis={{ rows: 1 }}
                level={5}
              >
                {title}
              </Title>
            </Label>
          </Col>
          <Col
            flex="none"
            onClick={stopPropagation}
            style={{ paddingRight: '0px' }}
          >
            <RevisionDeadlineMessage
              editorialAssistant={editorialAssistant}
              currentUserRole={currentUserRole}
              revisionDeadlineDate={revisionDeadlineDate}
            />
          </Col>
          <Col flex="none" style={{ paddingLeft: '0px' }}>
            <StatusTag
              hasVersion={!!peerReviewEditorialMapping}
              statusColor={getStatusCategory(status)}
              version={version}
            >
              {visibleStatus}
            </StatusTag>
          </Col>
        </Row>

        <Row gutter={[16, 12]}>
          {journal && (
            <Fragment>
              <Col span={8}>
                <Label>Journal</Label>
                <Text ellipsis>{journal.name}</Text>
              </Col>

              {renderEditorsCells({
                triageEditor,
                triageEditorLabel:
                  peerReviewEditorialMapping?.peerReviewEditorialModel
                    ?.triageEditor?.label || peerReviewModel.triageEditorLabel,
                academicEditor,
                academicEditorLabel:
                  peerReviewEditorialMapping?.peerReviewEditorialModel
                    ?.academicEditor?.label ||
                  peerReviewModel.academicEditorLabel,
              })}
            </Fragment>
          )}
          {authors && authors.length && (
            <Col span={8}>
              <Label>Authors</Label>
              <AuthorTagList authors={authors} withTooltip />
            </Col>
          )}
        </Row>

        {showMore &&
          renderShowMore({ section, reviewers, editors, specialIssue })}

        <Row gutter={[16, 12]}>
          <Col>
            <div onClick={stopPropagation}>
              <Link onClick={() => setShowMore(!showMore)}>
                <Space>
                  {showMore ? <IconCaretUp /> : <IconCaretDown />}
                  <span>Show {showMore ? 'less' : 'more'} details</span>
                </Space>
              </Link>
            </div>
          </Col>
        </Row>
      </Content>

      <Footer>
        <Row gutter={[24, 0]}>
          {submittedDate && status !== 'draft' && (
            <DateParser humanizeThreshold={0} timestamp={submittedDate}>
              {(timestamp, timeAgo) => (
                <Col>
                  <Label> Submitted date </Label>
                  <Text>{`${timestamp} (${timeAgo})`}</Text>
                </Col>
              )}
            </DateParser>
          )}

          {updated && (
            <DateParser humanizeThreshold={0} timestamp={updated}>
              {(timestamp, timeAgo) => (
                <Col>
                  <Label> Updated on </Label>
                  <Text>{`${timestamp} (${timeAgo})`}</Text>
                </Col>
              )}
            </DateParser>
          )}

          {articleType && articleType.name && (
            <Col>
              <Label> Article Type </Label>
              <span>{articleType.name}</span>
            </Col>
          )}
        </Row>
      </Footer>
    </Card>
  )
}
