const articleTypes = {
  editorial: 'Editorial',
  retraction: 'Retraction',
  letterToTheEditor: 'Letter to the Editor',
  expressionOfConcern: 'Expression of Concern',
  erratum: 'Erratum',
  corrigendum: 'Corrigendum',
  caseSeries: 'Case Series',
  caseReport: 'Case Report',
  reviewArticle: 'Review Article',
  researchArticle: 'Research Article',
  abstracts: 'Abstracts',
  bookReview: 'Book Review',
  briefCommunication: 'Brief Communication',
  briefReport: 'Brief Report',
  commentary: 'Commentary',
  invitedReview: 'Invited Review',
  literatureReview: 'Literature Review',
  meetingReport: 'Meeting Report',
  newAlleleAlert: 'New Allele Alert',
  operativeTechnique: 'Operative Technique',
  opinion: 'Opinion',
  originalArticle: 'Original Article',
  populationReport: 'Population Report',
  review: 'Review',
  shortCommunication: 'Short Communication',
  specialResearchReport: 'Special Research Report',
}
const shortArticleTypes = [
  articleTypes.editorial,
  articleTypes.retraction,
  articleTypes.letterToTheEditor,
  articleTypes.expressionOfConcern,
  articleTypes.erratum,
  articleTypes.corrigendum,
]
const articleTypesWithLinkedArticle = [
  articleTypes.retraction,
  articleTypes.letterToTheEditor,
  articleTypes.expressionOfConcern,
  articleTypes.erratum,
  articleTypes.corrigendum,
]
const articleTypesEligibleForSpecialIssue = [
  articleTypes.editorial,
  articleTypes.retraction,
  articleTypes.letterToTheEditor,
  articleTypes.expressionOfConcern,
  articleTypes.erratum,
  articleTypes.corrigendum,
  articleTypes.reviewArticle,
  articleTypes.researchArticle,
]

const articleTypesWithPeerReview = [
  articleTypes.caseSeries,
  articleTypes.caseReport,
  articleTypes.reviewArticle,
  articleTypes.researchArticle,
  articleTypes.briefCommunication,
  articleTypes.briefReport,
  articleTypes.commentary,
  articleTypes.invitedReview,
  articleTypes.operativeTechnique,
  articleTypes.originalArticle,
  articleTypes.populationReport,
  articleTypes.review,
  articleTypes.shortCommunication,
  articleTypes.bookReview,
  articleTypes.meetingReport,
  articleTypes.newAlleleAlert,
  articleTypes.opinion,
  articleTypes.literatureReview,
  articleTypes.specialResearchReport,
]

export const checkIfIsShortArticleType = (articleTypeLabel) =>
  shortArticleTypes.includes(articleTypeLabel)

export const checkIfAllowsLinkedArticle = (articleTypeLabel) =>
  articleTypesWithLinkedArticle.includes(articleTypeLabel)

export const checkIfCanBeSubmittedToSI = (articleTypeLabel) =>
  articleTypesEligibleForSpecialIssue.includes(articleTypeLabel)

export const checkIfIsPeerReviewArticleType = (articleTypeLabel) =>
  articleTypesWithPeerReview.includes(articleTypeLabel)
