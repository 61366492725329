import React from 'react'
import { isNumber } from 'lodash'
import { FieldArray } from 'formik'
import styled from 'styled-components'
import { IconExpand, Spinner, Upload } from '@hindawi/phenom-ui'
import {
  getSupportedFileFormats,
  FileLayoutWithScanning,
} from '../../../component-files/client'
import { compose, setDisplayName, withHandlers, withProps } from 'recompose'
import {
  Row,
  Text,
  Item,
  Label,
  ActionLink,
  withFetching,
  radiusHelpers,
} from '@hindawi/ui'

export const Files = ({
  files,
  title,
  remove,
  isLast,
  isFirst,
  compact,
  required,
  listName,
  onUpload,
  maxFiles,
  isFetching,
  isFileOver,
  fetchingError,
  isFileItemOver,
  canDropFileItem,
  supportedFormats,
  acceptedFileExtensions,
}) => (
  <Root
    compact={compact}
    data-test-id={`add-file-${listName}`}
    isFileItemOver={isFileOver || (isFileItemOver && canDropFileItem)}
    isFirst={isFirst}
    isLast={isLast}
  >
    <Row alignItems="flex-start" mb={2}>
      <Item alignItems="center" mt={2}>
        <Label required={required}>{title}</Label>
        {isFetching ? (
          <Spinner size={4} />
        ) : (
          <Upload
            accept={acceptedFileExtensions}
            disabled={files.length >= maxFiles}
            action={onUpload}
            showUploadList={false}
          >
            <ActionLink
              disabled={files.length >= maxFiles}
              fontSize="12px"
              fontWeight="bold"
              size="small"
              style={{ backgroundColor: '#ECF0F3', marginTop: 4 }}
            >
              <IconExpand
                style={{
                  marginLeft: 2,
                  marginRight: 2,
                  alignSelf: 'center',
                }}
              />
              UPLOAD FILE
            </ActionLink>
          </Upload>
        )}
      </Item>
      {supportedFormats && (
        <Item justify="flex-end">
          <Text fontSize="13px" mt={2} secondary>
            Supported file formats: {supportedFormats}
          </Text>
        </Item>
      )}
    </Row>

    {files.map((file) => (
      <FileLayoutWithScanning
        file={file}
        key={file.id}
        removeItemFromForm={() =>
          remove(files.findIndex((f) => f.id === file.id))
        }
      />
    ))}

    {fetchingError && (
      <Row mb={4}>
        <Text error>
          {fetchingError === 'Failed to fetch'
            ? 'Upload failed! Retry uploading the file.'
            : fetchingError}
        </Text>
      </Row>
    )}
  </Root>
)

export const EnhancedFiles = compose(
  withFetching,
  setDisplayName('FileSection'),
  withProps(({ allowedFileExtensions = [] }) => ({
    supportedFormats: getSupportedFileFormats(allowedFileExtensions),
    acceptedFileExtensions: allowedFileExtensions
      .map((ext) => '.' + ext)
      .join(', '),
  })),
  withHandlers({
    moveFile:
      ({ move }) =>
      (startIndex, endIndex) => {
        if (isNumber(startIndex) && isNumber(endIndex)) {
          move(startIndex, endIndex)
        }
      },
    onDelete:
      ({
        remove,
        setError,
        clearError,
        setFetching,
        onDeleteFile,
        files = [],
      }) =>
      (file) => {
        clearError()
        onDeleteFile(file, {
          remove,
          setError,
          setFetching,
          index: files.findIndex((f) => f.id === file.id),
        })
      },
    onUpload:
      ({
        push,
        listName,
        setError,
        clearError,
        setFetching,
        onUploadFile,
        setIsFileUploading,
      }) =>
      (file) => {
        clearError()
        if (typeof onUploadFile === 'function') {
          onUploadFile(file, {
            type: listName,
            push,
            setError,
            setFetching: (props) => {
              setFetching(props)
              setIsFileUploading(props)
            },
          })
        }
      },
  }),
)(Files)

const FileSection = ({ listName, setIsFileUploading, ...rest }) => (
  <FieldArray name={`files.${listName}`}>
    {(
      { push, remove, move }, // props injected by formik FieldArray component
    ) => (
      <EnhancedFiles
        listName={listName}
        move={move}
        push={push}
        remove={remove}
        setIsFileUploading={setIsFileUploading}
        {...rest}
      />
    )}
  </FieldArray>
)

export default FileSection

// #region styles
const Root = styled.div`
  background: ${(props) => (props.isFileItemOver ? '#DBDBDB' : '#ECF0F3')};
  min-height: calc(4px * ${({ compact }) => (compact ? 30 : 40)});
  padding: 0 8px 8px 8px;
  width: 100%;

  ${radiusHelpers};
  border-bottom: ${(props) => (!props.isLast ? '1px dashed #dbdbdb' : 'none')};
`
// #endregion
