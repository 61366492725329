import React, { ReactNode, Suspense, lazy } from 'react'
import { Switch, Redirect } from 'react-router-dom'
import { ROLES, ROUTES } from '@shared/ui/constants'
import FourOFour from './FourOFour'
import { useHasAccessToDashboard } from '@shared/ui/hooks'
import ReviewerRedirect from './ReviewerRedirect'

// For better performance, we suspense the pages, so they are splitted into chunks
const Dashboard = lazy(
  () => import('../../component-dashboard/client/pages/Dashboard'),
)
const EADashboard = lazy(
  () => import('../../component-dashboard/client/pages/EADashboard'),
)
const AdminSearchDashboard = lazy(
  () => import('../../component-dashboard/client/pages/AdminDashboard'),
)
const EQSDecision = lazy(
  () => import('../../component-screening/client/pages/EQSDecision'),
)
const InfoPage = lazy(
  () => import('../../component-authentication/client/pages/InfoPage'),
)
const AuthenticatedRoute = lazy(
  () => import('../../component-authentication/client/AuthenticatedRoute'),
)
const TrackedRoute = lazy(
  () => import('../../component-authentication/client/TrackedRoute'),
)
const ConfirmAccount = lazy(
  () => import('../../component-authentication/client/pages/ConfirmAccount'),
)
const ManuscriptDetails = lazy(
  () => import('../../component-peer-review/client/pages/ManuscriptDetails'),
)
const EmailResponse = lazy(
  () => import('../../component-peer-review/client/pages/EmailResponse'),
)
const ManuscriptDetailsGateway = lazy(
  () =>
    import(
      '../../component-peer-review/client/components/manuscriptDetails/ManuscriptDetailsGateway'
    ),
)
const SubmissionConfirmation = lazy(
  () =>
    import('../../component-submission/client/pages/SubmissionConfirmation'),
)
const Wizard = lazy(
  () => import('../../component-submission/client/pages/Wizard'),
)
const SubmissionFromURL = lazy(
  () => import('../../component-submission/client/pages/SubmissionFromURL'),
)
const UserProfilePage = lazy(
  () => import('../../component-user-profile/client/pages/UserProfilePage'),
)
const Unsubscribe = lazy(
  () => import('../../component-user-profile/client/pages/Unsubscribe'),
)
const AdminUsers = lazy(
  () => import('../../component-admin/client/pages/AdminUsers'),
)
const JournalsPage = lazy(
  () => import('../../component-admin/client/pages/AdminJournals'),
)
const AdminJournalDetails = lazy(
  () => import('../../component-admin/client/pages/AdminJournalDetails'),
)
const AdminDashboard = lazy(
  () => import('../../component-admin/client/pages/AdminDashboard'),
)
const Invitation = lazy(
  () => import('../../component-admin/client/pages/Invitation'),
)
const ConnectUserProfilePage = lazy(
  () =>
    import('../../component-user-profile/client/pages/ConnectUserProfilePage'),
)
const AdminEnMassJournalConfiguration = lazy(
  () =>
    import(
      '../../component-admin/client/pages/enmassJournalConfiguration/AdminEnMassJournalConfiguration'
    ),
)
import { LoadingState } from './LoadingState'

function Routes(): ReactNode {
  const hasAccessTo = useHasAccessToDashboard()

  return (
    <Suspense fallback={<LoadingState />}>
      <Switch>
        <TrackedRoute component={InfoPage} exact path="/info-page" />
        <TrackedRoute component={EQSDecision} exact path="/eqs-decision" />
        <TrackedRoute
          component={SubmissionConfirmation}
          exact
          path="/confirmation-page"
        />
        <TrackedRoute component={EmailResponse} exact path="/emails/:action" />
        <TrackedRoute
          component={ReviewerRedirect}
          exact
          path="/invite-reviewer"
        />
        <AuthenticatedRoute
          component={Invitation}
          exact
          path="/invitation/:invitationId"
        />
        <AuthenticatedRoute
          allow={[hasAccessTo('adminDashboard')]}
          component={AdminUsers}
          exact
          path={ROUTES.ADMIN_USERS}
        />
        <AuthenticatedRoute
          allow={[ROLES.ADMIN]}
          component={JournalsPage}
          exact
          path={ROUTES.ADMIN_JOURNALS}
        />
        {process.env.ENABLE_ENMASS_JOURNAL_CONFIGURATION && (
          <AuthenticatedRoute
            allow={[hasAccessTo('adminDashboard')]}
            component={AdminEnMassJournalConfiguration}
            exact
            path={ROUTES.ENMASS_JOURNAL_CONFIGURATION}
          />
        )}
        <AuthenticatedRoute
          allow={[ROLES.ADMIN]}
          component={AdminJournalDetails}
          exact
          path={ROUTES.JOURNAL_DETAILS}
        />
        <AuthenticatedRoute
          allow={[ROLES.ADMIN]}
          component={AdminDashboard}
          exact
          path={ROUTES.ADMIN_DASHBOARD}
        />

        <AuthenticatedRoute component={Dashboard} exact path="/" />
        <AuthenticatedRoute
          allow={[hasAccessTo('editorialAssistantDashboard')]}
          component={EADashboard}
          exact
          path={ROUTES.EA_DASHBOARD}
        />
        <AuthenticatedRoute
          allow={[hasAccessTo('adminDashboard')]}
          component={AdminSearchDashboard}
          exact
          path={ROUTES.ADMIN_SEARCH_DASHBOARD}
        />

        <AuthenticatedRoute
          component={ManuscriptDetailsGateway}
          exact
          path={ROUTES.MANUSCRIPT_DETAILS_GATEWAY}
        />

        <AuthenticatedRoute
          component={ManuscriptDetails}
          exact
          path={ROUTES.MANUSCRIPT_DETAILS}
        />

        <AuthenticatedRoute
          component={Wizard}
          exact
          path="/submit/:submissionId/:manuscriptId"
        />

        <AuthenticatedRoute
          component={SubmissionFromURL}
          exact
          path="/submit"
        />
        <AuthenticatedRoute
          component={
            process.env.CONNECT_ENABLED
              ? ConnectUserProfilePage
              : UserProfilePage
          }
          exact
          path={ROUTES.PROFILE}
        />

        <AuthenticatedRoute component={ConfirmAccount} exact path="/invite" />

        <TrackedRoute
          component={FourOFour}
          exact
          path={ROUTES.PAGE_NOT_FOUND}
        />
        <TrackedRoute component={Unsubscribe} exact path="/subscribe" />
        <TrackedRoute component={Unsubscribe} exact path="/unsubscribe" />
        <Redirect to={ROUTES.PAGE_NOT_FOUND} />
      </Switch>
    </Suspense>
  )
}

export default Routes
