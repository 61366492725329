import React from 'react'
import './successAnimation.css'

const SuccessAnimation = ({
  scale = 2.5,
  marginTop = '0px',
  marginBottom = '0px',
}) => (
  <svg
    className="checkmark"
    //@ts-ignore
    style={{ '--scale': scale, marginTop, marginBottom }}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 52 52"
  >
    <circle
      className="checkmark checkmarkCircle"
      cx="26"
      cy="26"
      r="25"
      fill="none"
    />
    <path
      className="checkmark checkmarkCheck"
      fill="none"
      d="M14.1 27.2l7.1 7.2 16.7-16.8"
    />
  </svg>
)

export default SuccessAnimation
