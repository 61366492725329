import React from 'react'
import { LogoContainer, Root } from '../sharedStyledComponents'
import { IconWileyLogo } from '@hindawi/phenom-ui'

const UnauthenticatedAppBarContent = ({ goTo }) => {
  return (
    <Root>
      <LogoContainer>
        <IconWileyLogo onClick={() => goTo('/')} />
      </LogoContainer>
    </Root>
  )
}

export default UnauthenticatedAppBarContent
