// @ts-nocheck
import React, { useState, useEffect } from 'react'
import { useLazyQuery } from 'react-apollo'
import { Item, Label, ValidatedFormField, SearchableSelect } from '@hindawi/ui'
import { Col, Input, Radio, Row, Space } from '@hindawi/phenom-ui'
import { queries } from '../../graphql'
import { Field } from 'formik'

const YES_OR_NO_OPTIONS = [
  { value: 'yes', label: 'Yes' },
  { value: 'no', label: 'No' },
]
interface JournalTransferProps {
  formValues: any
  validators: any
  setFieldValue: (field: string, payload: any) => void
}

export const JournalTransfer: React.FC<JournalTransferProps> = ({
  formValues,
  setFieldValue,
  validators,
}) => {
  const { sourceJournalId } = formValues
  const [sourceJournals, setSourceJournals] = useState([])
  const isTransfer = formValues.isTransfer === 'yes'
  const query = 'getSourceJournals'
  const [queryJournals, { data, loading }] = useLazyQuery(queries[query])

  useEffect(() => {
    // we only want to run this query when:
    // 1. user just started the submission and pressed yes button
    // 2. user started the flow in the past and has selected a source journal
    if ((isTransfer && !sourceJournals.length) || sourceJournalId) {
      queryJournals()
    }
  }, [isTransfer])

  useEffect(() => {
    if (data && data[query]) {
      const sourceJournals = data[query].map(({ id, name }) => ({
        value: id,
        label: name,
      }))

      setSourceJournals(sourceJournals)
    }
  }, [data])

  const onSelectJournal = (selectedJournal) => {
    const updatedValue = selectedJournal ? selectedJournal.value : null
    setFieldValue('sourceJournalId', updatedValue)
  }

  // prevent rendering anything if we have to prefill the field with a transfer journal
  // this way we also avoid a bug where the Yes radio button is preselected
  // but the field is not prefilled
  if (sourceJournalId && !sourceJournals.length) return null

  return (
    <>
      <Col>
        <Label>
          Are you transferring this manuscript from another journal?
        </Label>
        <Field name="isTransfer">
          {({ field }) => (
            <Radio.Group
              name="isTransfer"
              onChange={(e) => {
                const { value } = e.target
                setFieldValue('isTransfer', value)

                if (value === 'no') {
                  setFieldValue('sourceJournalId', null)
                  setFieldValue('sourceJournalManuscriptId', '')
                }
              }}
              defaultValue={field.value}
              data-test-id="is-transfer-radio-group"
            >
              <Space
                direction="horizontal"
                style={{ marginBottom: '20px', marginTop: '8px' }}
              >
                {YES_OR_NO_OPTIONS.map(({ label, value }) => (
                  <Radio key={value} value={value} checked={field.value}>
                    {label}
                  </Radio>
                ))}
              </Space>
            </Radio.Group>
          )}
        </Field>
      </Col>

      {isTransfer && !loading && (
        <Row mb={4}>
          <Item data-test-id="source-journal" mr={2} vertical>
            <Label required>Source Journal</Label>
            <ValidatedFormField
              component={SearchableSelect}
              name="sourceJournalId"
              onChange={onSelectJournal}
              options={sourceJournals}
              placeholder="Select journal"
              validate={[validators.required]}
            />
          </Item>

          <Item data-test-id="source-journal-manuscript-id" vertical>
            <Label required>Source Journal Manuscript ID</Label>
            <ValidatedFormField
              component={Input}
              name="sourceJournalManuscriptId"
              onChange={(value) =>
                setFieldValue('sourceJournalManuscriptId', value)
              }
              placeholder="Source Journal Manuscript ID"
              validate={[validators.required]}
            />
          </Item>
        </Row>
      )}
    </>
  )
}
