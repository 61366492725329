/* eslint-disable react/jsx-no-comment-textnodes, sonarjs/cognitive-complexity */
import React, { Fragment, useEffect, useState } from 'react'
import styled from 'styled-components'
import {
  Row,
  Item,
  SearchableSelect,
  Label,
  ActionLink,
  validators,
  ValidatedFormField,
} from '@hindawi/ui'
import { get, flatMap, debounce } from 'lodash'
import { Modal } from '../../../component-modal'
import { useLazyQuery, useMutation } from 'react-apollo'
import { Alert, Radio, Select, Space, Title } from '@hindawi/phenom-ui'

import { queries, mutations } from '../graphql'

import CallForPapers from './CallForPapersModal'
import {
  checkIfCanBeSubmittedToSI,
  checkIfIsShortArticleType,
  checkIfAllowsLinkedArticle,
} from '..'
import { Field } from 'formik'

const issueTypesOptions = [
  {
    label: 'Regular Issue',
    value: 'regularIssue',
  },
  {
    label: 'Special Issue',
    value: 'specialIssue',
  },
]

function convertToOptions(array = []) {
  return array.map(({ id, name }) => ({
    label: name,
    value: id,
  }))
}

function getArticleTypes({ formValues, selectedJournal }) {
  const isSpecialIssue = formValues.issueType === 'specialIssue'
  return isSpecialIssue
    ? convertToOptions(
        selectedJournal.articleTypes.filter((articleType) =>
          checkIfCanBeSubmittedToSI(articleType.name),
        ),
      )
    : convertToOptions(selectedJournal.articleTypes)
}

function convertToOptionsWithCallForPapers(array) {
  return array.map(({ id, name, callForPapers, isActive }) => ({
    value: id,
    label: name,
    callForPapers,
    isActive,
  }))
}

function parsePublishedManuscripts(manuscriptData) {
  return manuscriptData.map(({ title, journal, customId }) => ({
    label: title,
    value: customId,
    journalId: journal.id,
    journalName: journal.name,
    linkedSubmissionCustomId: customId,
  }))
}

const Journal = ({
  shouldDisableEditJournal,
  isShortArticleType,
  articleTypeId,
  journalsOptions,
  resetFields,
  setFieldValue,
  journalId,
  formValues,
}) => {
  const onSelectJournal = (v) => {
    resetFields({
      journalId: v ? v.value : undefined,
      linkedSubmissionCustomId: undefined,
      issueType: 'regularIssue',
      sectionId: undefined,
      specialIssueId: undefined,
    })()
    setFieldValue('meta', {
      ...formValues.meta,
      articleTypeId: null,
    })
  }
  const findSelectedItem = (value, options) =>
    options.find((o) => o.value === value)

  return (
    <Row mt={10}>
      <Item data-test-id="journal-select" vertical>
        <Label mb={1} required={!isShortArticleType && !!articleTypeId}>
          Journal
        </Label>
        <ValidatedFormField
          component={SearchableSelect}
          disabled={shouldDisableEditJournal}
          name="journalId"
          onChange={onSelectJournal}
          options={journalsOptions}
          placeholder="Please select a journal"
          selectedItem={findSelectedItem(journalId, journalsOptions)}
          validate={[validators.required]}
        />
      </Item>
    </Row>
  )
}

const ArticleType = ({
  resetFields,
  articleTypesOptions,
  selectedJournal,
  onChangeArticleType,
}) => {
  const onSelectArticleType = (v) => {
    resetFields({
      articleTypeId: v ? v.value : undefined,
      linkedSubmissionCustomId: v ? v.linkedSubmissionCustomId : undefined,
      // get submission em configs
    })()

    onChangeArticleType(v)
  }

  return (
    <Row>
      <Item data-test-id="submission-type" vertical>
        <Label disabled={!selectedJournal} mb={1} required>
          Article Type
        </Label>
        <ValidatedFormField
          component={Select}
          disabled={!selectedJournal}
          name="meta.articleTypeId"
          onChange={onSelectArticleType}
          options={articleTypesOptions}
          placeholder="Please select an article type"
          validate={[validators.required]}
          style={{ width: '100%' }}
        />
      </Item>
    </Row>
  )
}

const LinkedArticle = ({
  resetFields,
  onInputValueChange,
  manuscriptsOptions,
}) => {
  const onSelectLinkedArticle = (v) =>
    resetFields({
      linkedSubmissionCustomId: v ? v.linkedSubmissionCustomId : undefined,
    })()

  const debouncedOnInputValueChange = debounce(onInputValueChange, 500)

  return (
    <Row>
      <Item data-test-id="submission-type" vertical>
        <Label mb={1}> Linked Article </Label>
        <ValidatedFormField
          component={SearchableSelect}
          name="linkedSubmissionCustomId"
          onChange={onSelectLinkedArticle}
          onInputValueChange={debouncedOnInputValueChange}
          options={manuscriptsOptions}
          placeholder="Please select article title"
          validate={[]}
        />
      </Item>
    </Row>
  )
}

const IssueType = ({
  resetFields,
  issueTypeValue,
  formValues,
  setFieldValue,
}) => {
  return (
    <Fragment>
      <Row>
        <Item vertical>
          <Label mb={1}>Submit to an Issue</Label>
        </Item>
      </Row>
      <Row>
        <Item data-test-id="issue-selector" vertical>
          <Field name="issueType">
            {({ field }) => (
              <Radio.Group
                name="issueType"
                onChange={(e) => {
                  resetFields({
                    specialIssueId: undefined,
                    sectionId: undefined,
                    linkedSubmissionCustomId: undefined,
                  })()
                  setFieldValue('meta', {
                    ...formValues.meta,
                    articleTypeId: null,
                  })
                  field.onChange(e)
                }}
                defaultValue={issueTypeValue}
                data-test-id="select-issue-type-radio-group"
              >
                <Space direction="horizontal" style={{ marginBottom: '20px' }}>
                  {issueTypesOptions.map(({ label, value }) => (
                    <Radio key={value} value={value} checked={field.value}>
                      {label}
                    </Radio>
                  ))}
                </Space>
              </Radio.Group>
            )}
          </Field>
        </Item>
      </Row>
    </Fragment>
  )
}

const Section = ({
  sectionsOptions,
  resetFields,
  setFieldValue,
  formValues,
}) => {
  const onSelectSection = (v) => {
    resetFields({
      sectionId: v ? v.value : undefined,
      linkedSubmissionCustomId: undefined,
    })()
    setFieldValue('meta', {
      ...formValues.meta,
      articleTypeId: null,
    })
  }

  return (
    <Row>
      <Item data-test-id="section-select" vertical>
        <Label mb={1} required>
          Section
        </Label>
        <ValidatedFormField
          component={Select}
          name="sectionId"
          onChange={onSelectSection}
          options={sectionsOptions}
          placeholder="Select section"
          validate={[validators.required]}
          style={{ width: '100%' }}
        />
      </Item>
    </Row>
  )
}

const SpecialIssue = ({
  specialIssueOptions,
  resetFields,
  setFieldValue,
  formValues,
}) => {
  const onSelectSpecialIssue = (v) => {
    resetFields({
      specialIssueId: v ? v.value : undefined,
      sectionId: undefined,
      issueType: 'specialIssue',
      linkedSubmissionCustomId: undefined,
    })()
    setFieldValue('meta', {
      ...formValues.meta,
      articleTypeId: null,
    })
  }

  return (
    <Row>
      <Item data-test-id="special-issue-select" vertical>
        <Label mb={1} required>
          Special Issue
        </Label>
        <ValidatedFormField
          component={SearchableSelect}
          name="specialIssueId"
          onChange={onSelectSpecialIssue}
          options={specialIssueOptions}
          placeholder="Select special issue"
          validate={[validators.required]}
        />
      </Item>
    </Row>
  )
}

const CallForPapersC = ({ callForPapers }) => (
  <Row mt={2}>
    <Item alignItems="center">
      <Modal
        callForPapers={callForPapers}
        component={CallForPapers}
        modalKey="callForPapers"
      >
        {(showModal) => (
          <ActionLink
            data-test-id="call-for-papers"
            flex={1}
            fontSize="13px"
            fontWeight="bold"
            onClick={showModal}
          >
            Please read Call for Papers
          </ActionLink>
        )}
      </Modal>
    </Item>
  </Row>
)

function conditionallyRenderArticleType({
  articleTypesOptions,
  resetFields,
  selectedJournal,
  onChangeArticleType,
}) {
  return (
    <ArticleType
      articleTypesOptions={articleTypesOptions}
      onChangeArticleType={onChangeArticleType}
      resetFields={resetFields}
      selectedJournal={selectedJournal}
    />
  )
}
function renderError(submissionEditorialMappingError) {
  if (!submissionEditorialMappingError) return null

  return (
    <StyledAlert
      description="Please wait for a while and then retry selecting the article type."
      message="We apologise, something went wrong"
      type="error"
    />
  )
}

function conditionallyRenderLinkedArticle({
  selectedArticleType,
  onLinkedSubmissionInputValueChange,
  publishedManuscriptsByLinkedArticleType,
  resetFields,
}) {
  const isLinkedArticleVisible =
    selectedArticleType && checkIfAllowsLinkedArticle(selectedArticleType.label)

  if (!isLinkedArticleVisible) {
    return null
  }

  return (
    <LinkedArticle
      manuscriptsOptions={publishedManuscriptsByLinkedArticleType}
      onInputValueChange={onLinkedSubmissionInputValueChange}
      resetFields={resetFields}
    />
  )
}

function conditionallyRenderIssueType({
  selectedJournal,
  issueTypeValue,
  resetFields,
  formValues,
  setFieldValue,
  specialIssueOptions,
}) {
  return selectedJournal && !!specialIssueOptions.length ? (
    <IssueType
      formValues={formValues}
      issueTypeValue={issueTypeValue}
      resetFields={resetFields}
      setFieldValue={setFieldValue}
    />
  ) : null
}

function conditionallyRenderSection({
  selectedJournal,
  issueTypeValue,
  resetFields,
  setFieldValue,
  formValues,
}) {
  const hasSectionsDropdown =
    selectedJournal &&
    !!selectedJournal.sections.length &&
    issueTypeValue === 'regularIssue'

  if (!hasSectionsDropdown) return null

  return (
    <Section
      formValues={formValues}
      resetFields={resetFields}
      sectionsOptions={convertToOptions(selectedJournal.sections)}
      setFieldValue={setFieldValue}
    />
  )
}

function conditionallyRenderSpecialIssue({
  selectedJournal,
  issueTypeValue,
  specialIssueOptions,
  resetFields,
  formValues,
  setFieldValue,
}) {
  const hasIssuesDropdown =
    selectedJournal &&
    !!specialIssueOptions.length &&
    issueTypeValue === 'specialIssue'

  if (!hasIssuesDropdown) return null
  return (
    <SpecialIssue
      formValues={formValues}
      resetFields={resetFields}
      setFieldValue={setFieldValue}
      specialIssueOptions={specialIssueOptions}
    />
  )
}

function conditionallyRenderCallForPapers({
  specialIssueSelected,
  selectedJournal,
  issueTypeValue,
  specialIssueOptions,
}) {
  const hasIssuesDropdown =
    selectedJournal &&
    !!specialIssueOptions.length &&
    issueTypeValue === 'specialIssue'

  const callForPapers = get(specialIssueSelected, 'callForPapers')

  if (!hasIssuesDropdown || !specialIssueSelected) return null
  return <CallForPapersC callForPapers={callForPapers} />
}

const WizardStepOne = ({
  shouldDisableEditJournal,
  setValues,
  formValues,
  setFieldValue,
  activeJournals: journals,
  journal: selectedJournal,
  submissionEditorialModel,
  setSubmissionEditorialModel,
  setIsEditorialModelFetching,
}) => {
  const issueTypeValue = get(formValues, 'issueType')
  const specialIssueOptions =
    selectedJournal &&
    convertToOptionsWithCallForPapers([
      ...selectedJournal.specialIssues,
      ...flatMap(selectedJournal.sections, (section) => section.specialIssues),
    ])

  const specialIssueId = get(formValues, 'specialIssueId')
  const specialIssueSelected =
    specialIssueId &&
    selectedJournal &&
    specialIssueOptions.find((si) => si.value === specialIssueId)

  const articleTypesOptions = selectedJournal
    ? getArticleTypes({ formValues, selectedJournal })
    : []

  const articleTypeId = get(formValues, 'meta.articleTypeId', undefined)
  const selectedArticleType =
    articleTypeId &&
    articleTypesOptions.find((at) => at.value === articleTypeId)
  const isShortArticleType =
    selectedArticleType && checkIfIsShortArticleType(selectedArticleType.label)

  const { linkedSubmissionCustomId } = formValues

  const [
    publishedManuscriptsByLinkedArticleType,
    setPublishedManuscriptsByLinkedArticleType,
  ] = useState([])

  const [queryManuscriptByCustomId, { data: manuscriptsByCustomIdData }] =
    useLazyQuery(queries.getManuscriptByCustomId)

  useEffect(() => {
    if (linkedSubmissionCustomId) {
      queryManuscriptByCustomId({
        variables: { input: linkedSubmissionCustomId },
      })
    }
  }, [linkedSubmissionCustomId])

  useEffect(() => {
    if (manuscriptsByCustomIdData)
      setPublishedManuscriptsByLinkedArticleType(
        parsePublishedManuscripts(
          new Array(
            get(manuscriptsByCustomIdData, 'getManuscriptByCustomId', {}),
          ),
        ),
      )
  }, [manuscriptsByCustomIdData])

  const [getPublishedManuscriptsQuery, { data: publishedManuscriptsData }] =
    useLazyQuery(queries.getPublishedManuscripts)

  useEffect(() => {
    if (publishedManuscriptsData)
      setPublishedManuscriptsByLinkedArticleType(
        parsePublishedManuscripts(
          get(publishedManuscriptsData, 'getPublishedManuscripts', []),
        ),
      )
  }, [publishedManuscriptsData])

  const [
    getSubmissionEditorialModel,
    {
      data: submissionEditorialMapping,
      error: submissionEditorialMappingError,
    },
  ] = useLazyQuery(queries.getSubmissionEditorialModel, {
    fetchPolicy: 'network-only',
    onCompleted: () => {
      setIsEditorialModelFetching(false)
    },
    onError: () => {
      setIsEditorialModelFetching(false)

      resetFields({
        linkedSubmissionCustomId: undefined,
      })()
      setFieldValue('meta', {
        ...formValues.meta,
        articleTypeId: null,
      })
    },
  })

  const [setSubmissionEditorialMapping] = useMutation(
    mutations.setSubmissionEditorialMapping,
  )

  function prepareManuscriptEditorialModelSave(submissionEditorialModel) {
    const emProps = submissionEditorialModel.getSubmissionEditorialModel

    delete emProps.__typename
    return emProps
  }

  useEffect(() => {
    if (!submissionEditorialMapping || !formValues.submissionId) return
    setSubmissionEditorialMapping({
      variables: {
        submissionId: formValues.submissionId,
        submissionEditorialModel: prepareManuscriptEditorialModelSave(
          submissionEditorialMapping,
        ),
      },
    })
      .then(({ data }) => {
        setSubmissionEditorialModel(
          data.setSubmissionEditorialMapping.submissionEditorialModel,
        )
      })
      .catch((e) => {})
  }, [submissionEditorialMapping])

  const resetFields =
    (options = {}) =>
    () => {
      setValues({
        ...formValues,
        meta: {
          ...formValues.meta,
          conflictOfInterest: '',
          dataAvailability: '',
          fundingStatement: '',
        },

        ...options,
      })
    }

  const onLinkedSubmissionInputValueChange = (inputValue) => {
    if (inputValue) {
      getPublishedManuscriptsQuery({
        variables: {
          input: inputValue,
          journalId: formValues.journalId,
          sectionId: formValues.sectionId,
          specialIssueId: formValues.specialIssueId,
        },
      })
    } else {
      setPublishedManuscriptsByLinkedArticleType([])
    }
  }

  const onChangeArticleType = (selectedValue) => {
    const articleTypeOption = articleTypesOptions.find(
      (at) => at.value === selectedValue,
    )
    setIsEditorialModelFetching(true)
    getSubmissionEditorialModel({
      variables: {
        submissionId: formValues.submissionId,
        journalId: formValues.journalId,
        issueType: formValues.issueType,
        articleType: articleTypeOption?.label,
        isOnSection: !!formValues.sectionId,
      },
    })
  }

  return (
    <Fragment>
      <Title level={4} style={{ textAlign: 'center' }}>
        1. Journal & Article Type
      </Title>
      <Journal
        articleTypeId={articleTypeId}
        formValues={formValues}
        isShortArticleType={isShortArticleType}
        journalId={formValues.journalId}
        journalsOptions={convertToOptions(journals)}
        linkedSubmissionCustomId={linkedSubmissionCustomId}
        resetFields={resetFields}
        setFieldValue={setFieldValue}
        shouldDisableEditJournal={shouldDisableEditJournal}
      />
      {conditionallyRenderIssueType({
        selectedJournal,
        issueTypeValue,
        resetFields,
        setFieldValue,
        formValues,
        specialIssueOptions,
      })}
      {conditionallyRenderSection({
        selectedJournal,
        issueTypeValue,
        resetFields,
        formValues,
        setFieldValue,
      })}
      {conditionallyRenderSpecialIssue({
        selectedJournal,
        issueTypeValue,
        specialIssueOptions,
        resetFields,
        formValues,
        setFieldValue,
      })}
      {conditionallyRenderCallForPapers({
        specialIssueSelected,
        selectedJournal,
        specialIssueOptions,
        issueTypeValue,
      })}
      {conditionallyRenderArticleType({
        formValues,
        resetFields,
        selectedJournal,
        articleTypesOptions,
        onChangeArticleType,
      })}
      {conditionallyRenderLinkedArticle({
        selectedArticleType,
        resetFields,
        onLinkedSubmissionInputValueChange,
        publishedManuscriptsByLinkedArticleType,
      })}
      {renderError(submissionEditorialMappingError)}
    </Fragment>
  )
}

export default WizardStepOne

const StyledAlert = styled(Alert)`
  padding-bottom: 20px;
  padding-top: 4px;
`
