/* eslint-disable no-nested-ternary */
import React, { Fragment } from 'react'
import styled, { withTheme } from 'styled-components'
import { Row } from '@hindawi/ui'
import {
  Spinner,
  Button as BaseButton,
  IconArrowLeft,
  IconArrowRight,
} from '@hindawi/phenom-ui'

const isDisabled = ({
  journal,
  isFirst,
  isFetchingEditorialModel,
  hasArticleType,
  hasSubmissionEditorialModel,
}) => {
  if (!journal && !isFirst) return true
  if (isFetchingEditorialModel) return true
  return hasArticleType && !hasSubmissionEditorialModel
}

const WizardButtons = ({
  isLast,
  isFirst,
  history,
  prevStep,
  editMode,
  isFetching,
  handleSubmit,
  journal,
  isFileUploading,
  isFetchingEditorialModel,
  hasArticleType,
  hasSubmissionEditorialModel,
}) => (
  <Row justify="center" mt={9}>
    {isFetching || isFileUploading ? (
      <Spinner />
    ) : (
      <Fragment>
        <Button
          onClick={isFirst ? () => history.goBack() : prevStep}
          type="ghost"
          size="large"
          style={{ marginRight: '48px' }}
        >
          <IconArrowLeft />
          BACK
        </Button>
        <Button
          disabled={isDisabled({
            journal,
            isFirst,
            isFetchingEditorialModel,
            hasArticleType,
            hasSubmissionEditorialModel,
          })}
          onClick={handleSubmit}
          type="primary"
          size="large"
        >
          {isLast
            ? editMode
              ? 'SAVE CHANGES'
              : 'SUBMIT MANUSCRIPT'
            : 'NEXT STEP'}
          <IconArrowRight />
        </Button>
      </Fragment>
    )}
  </Row>
)

const Button = styled(BaseButton)`
  width: 192px;
  line-height: 19px;
  :not(.btn-tertiary) span:last-child:not(.anticon) {
    min-width: 20px;
  }
`

export default withTheme(WizardButtons)
// #endregion
