import WizardStepOne from './WizardStepOne'
import WizardStepTwo from './WizardStepTwo'
import WizardStepThree from './WizardStepThree'
import WizardStepFour from './WizardStepFour'

export { default as WizardFiles } from './WizardFiles'
export { default as WizardAuthors } from './WizardAuthors'
export { default as WizardButtons } from './WizardButtons'
export { default as AutosaveIndicator } from './AutosaveIndicator'
export { default as SubmissionStatement } from './SubmissionStatement'
export { WizardPreprint } from './WizardPreprint'

export const wizardSteps = [
  {
    title: '1. Article Type & Journal',
    component: WizardStepOne,
  },
  {
    title: '2. Pre-submission Checklist',
    component: WizardStepTwo,
  },
  {
    title: '3. Manuscript & Author Details',
    component: WizardStepThree,
  },
  {
    title: '4. Files Upload',
    component: WizardStepFour,
  },
]
