import React, { Fragment, useState } from 'react'
import { Label } from '../../component-hindawi-ui'
import styled from 'styled-components'
import {
  Form,
  Input,
  Button,
  message,
  IconCaretDown,
  RorAutocomplete,
  ConfirmationModal,
  IconErrorSolid,
  Space,
} from '@hindawi/phenom-ui'
import { useMutation } from 'react-apollo'
import { mutations } from './graphql'
import { useKeycloak } from '../../component-sso/client'

export const UpdateAffiliationModal = ({ userKeycloakId }) => {
  const [selectedRor, setSelectedRor] = useState({ name: '', id: '' })
  const [updateAffiliationMutation] = useMutation(mutations.updateAffiliation)
  const [isModalOpen, setModalOpen] = useState(true)
  const [isSaving, setIsSaving] = useState(false)
  const [form] = Form.useForm()
  const { keycloak } = useKeycloak()

  const errorMessage = [
    {
      name: 'name',
      errors: [
        <div key={userKeycloakId}>
          <IconErrorSolid /> Something went wrong, please try again. If the
          issue persists, contact reviewhelp@wiley.com
        </div>,
      ],
    },
  ]
  const handleSubmit = (values, form) => {
    setIsSaving(true)
    updateAffiliationMutation({
      variables: {
        input: {
          affiliation: values.name,
          rorId: values.id,
          userKeycloakId: userKeycloakId,
        },
      },
    })
      .then((response) => {
        if (response.data.updateAffiliation) {
          setModalOpen(false)
          void message.success({
            content:
              'Your affiliation has been saved. You can edit it on your profile page',
            className: 'add-affiliation-notification',
          })
          // force token refresh to pick up updated affiliation
          void (keycloak ? keycloak.forceUpdateToken() : null)
        } else {
          setIsSaving(false)
          form.setFields(errorMessage)
        }
      })
      .catch(() => {
        setIsSaving(false)
        form.setFields(errorMessage)
      })
  }

  return (
    <StyledConfirmationModal
      onOk={() => {}}
      title="Missing affiliation"
      subtitle="Please select your affiliation from the list below. If your institution is not on the list, please type the institution’s name and click ‘Save’."
      open={isModalOpen}
      closable={false}
      icon="alert"
      footer={null}
    >
      <Form
        form={form}
        layout="vertical"
        onFinish={() => {
          handleSubmit(selectedRor, form)
        }}
      >
        <Space size={10} direction="vertical" style={{ width: '100%' }}>
          <StyledItem name="name">
            <Fragment>
              <Label style={{ marginTop: '16px' }} required>
                Affiliation
              </Label>
              <RorAutocomplete
                dropdownMatchSelectWidth={200}
                selectedRor={selectedRor}
                setSelectedRor={setSelectedRor}
              >
                <Input
                  placeholder="Type institution name"
                  suffix={<IconCaretDown />}
                />
              </RorAutocomplete>
            </Fragment>
          </StyledItem>
          <Form.Item>
            <Button
              type="primary"
              style={{ float: 'right' }}
              disabled={!selectedRor.name || isSaving}
              htmlType="submit"
            >
              Save
            </Button>
          </Form.Item>
        </Space>
      </Form>
    </StyledConfirmationModal>
  )
}

const StyledConfirmationModal = styled(ConfirmationModal)`
  width: 580px !important;
  .ant-modal-body {
    padding: 40px 40px 12px 40px;
  }
  .anticon {
    align-items: baseline;
    margin-inline-end: 16px !important;
  }
`

const StyledItem = styled(Form.Item)`
  .anticon > svg {
    width: 14px !important;
    height: 14px !important;
  }
  .anticon {
    margin-inline-end: 0px !important;
  }
`
