import React from 'react'

import JournalContext from './JournalInfoContext'

function JournalProvider({ children, publisher, enableWileyRebranding }) {
  return (
    <JournalContext.Provider value={{ ...publisher, enableWileyRebranding }}>
      {children}
    </JournalContext.Provider>
  )
}

export default JournalProvider
