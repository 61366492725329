/* eslint-disable sonarjs/cognitive-complexity */
import React, { useEffect, useRef, useState } from 'react'
import { useMutation, useQuery } from 'react-apollo'
import { FileCard } from '@hindawi/phenom-ui'

import { useFileDownload } from '../decorators'
import { fileHasPreview } from '../fileUtils'

import {
  deleteFile as DELETE_FILE_MUTATION,
  getSignedUrl as GET_SIGNED_URL_MUTATION,
} from '../graphql/mutations'
import { getFileScanStatus as GET_FILE_SCAN_STATUS_QUERY } from '../graphql/queries'

import {
  ANTIVIRUS_SCAN_POLLING_INTERVAL,
  ANTIVIRUS_TIMEOUT_POLLING_INTERVAL,
  ANTIVIRUS_TIMEOUT_POLLING_RETRY_COUNT,
} from './constants'

const FileScanStatuses = Object.freeze({
  SKIPPED: 'skipped',
  SCANNING: 'scanning',
  INFECTED: 'infected',
  HEALTHY: 'healthy',
  TIMEOUT_PASSED: 'timeoutPassed',
  ERROR: 'error',
})

const FileLayoutWithScanning = (props) => {
  const { file, removeItemFromForm } = props
  const { filename, id, size } = file
  const timerID = useRef(null)
  const [status, setStatus] = useState({
    previousStatus: null,
    currentStatus: null,
  })

  const {
    data: fileScanData,
    loading: fileScanDataLoading,
    error: fileScanError,
    startPolling,
    stopPolling,
  } = useQuery(GET_FILE_SCAN_STATUS_QUERY, {
    variables: {
      fileId: id,
    },
  })
  const { downloadFile } = useFileDownload(file)
  const [getSignedUrl] = useMutation(GET_SIGNED_URL_MUTATION)
  const [deleteFile] = useMutation(DELETE_FILE_MUTATION)

  const onPreview = async () => {
    try {
      const response = await getSignedUrl({ variables: { fileId: id } })
      const { getSignedUrl: signedUrl } = response.data

      window.open(signedUrl)
    } catch (e) {
      setError(e)
    }
  }
  const onDelete = async () => {
    try {
      await deleteFile({ variables: { fileId: id } })
      removeItemFromForm()
    } catch (e) {
      setError(e)
    }
  }

  const cleanupPolling = (timerID) => {
    clearTimeout(timerID)
    stopPolling()
  }

  useEffect(() => {
    const { scanStatus } = fileScanData?.getFileInfo || {}
    const isScanning = scanStatus === FileScanStatuses.SCANNING
    const isTimeoutPassed = scanStatus === FileScanStatuses.TIMEOUT_PASSED

    if (isScanning) {
      startPolling(ANTIVIRUS_SCAN_POLLING_INTERVAL)
    }

    if (isTimeoutPassed) {
      startPolling(ANTIVIRUS_TIMEOUT_POLLING_INTERVAL)
      timerID.current = setTimeout(
        stopPolling,
        ANTIVIRUS_TIMEOUT_POLLING_INTERVAL *
          ANTIVIRUS_TIMEOUT_POLLING_RETRY_COUNT,
      )
    }

    if (fileScanError && isTimeoutPassed) {
      cleanupPolling(timerID.current)
    }

    if (scanStatus) {
      setStatus((status) => ({
        previousStatus: status.currentStatus,
        currentStatus: scanStatus,
      }))
    }

    return () => {
      cleanupPolling(timerID.current)
    }
  }, [fileScanData, fileScanError, startPolling, stopPolling])

  if (fileScanDataLoading) {
    return (
      <FileCard
        file={{
          name: filename,
          uid: id,
          size,
          status: 'uploading',
          statusCustomMessage: 'Fetching scan status',
        }}
        withButtons={false}
        withWhiteBg
      />
    )
  }

  const { scanStatus } = fileScanData.getFileInfo
  const isPreviewVisible = fileHasPreview(file)

  if (scanStatus === FileScanStatuses.SCANNING)
    return (
      <FileCard
        file={{
          name: filename,
          uid: id,
          size,
          status: 'uploading',
          statusCustomMessage: 'Scanning for viruses',
        }}
        withButtons={false}
        withWhiteBg
      />
    )

  if (scanStatus === FileScanStatuses.ERROR) {
    return (
      <FileCard
        file={{
          name: filename,
          uid: id,
          size,
          status: 'error',
          statusCustomMessage: 'Scan failed! Retry scan.',
        }}
        onRemove={onDelete}
        withRetry={false}
        withWhiteBg
      />
    )
  }

  if (scanStatus === FileScanStatuses.INFECTED) {
    return (
      <FileCard
        file={{
          name: filename,
          uid: id,
          size,
          status: 'error',
          statusCustomMessage: 'File is infected! Please choose another file.',
        }}
        onRemove={onDelete}
        withRetry={false}
        withWhiteBg
      />
    )
  }

  if (
    [FileScanStatuses.SCANNING, FileScanStatuses.TIMEOUT_PASSED].includes(
      status.previousStatus,
    ) &&
    [FileScanStatuses.TIMEOUT_PASSED, FileScanStatuses.HEALTHY].includes(
      status.currentStatus,
    )
  ) {
    return (
      <FileCard
        file={{
          name: filename,
          uid: id,
          size,
          status: 'success',
        }}
        onDownload={downloadFile}
        onPreview={onPreview}
        onRemove={onDelete}
        withPreview={isPreviewVisible}
        withWhiteBg
      />
    )
  }

  return (
    <FileCard
      file={{
        name: filename,
        uid: id,
        size,
        status: 'existing',
      }}
      onDownload={downloadFile}
      onPreview={onPreview}
      onRemove={onDelete}
      withPreview={isPreviewVisible}
      withWhiteBg
    />
  )
}

export default FileLayoutWithScanning
