import React from 'react'
import PropTypes from 'prop-types'
import { ThemeProvider, createGlobalStyle } from 'styled-components'
import { normalize } from 'styled-normalize'

// @tippy.js/react styles
import 'tippy.js/dist/tippy.css'
import 'tippy.js/themes/light.css'

import HindawiApp from './HindawiApp'

const Root = ({ routes, theme }) => (
  <ThemeProvider theme={theme}>
    <HindawiApp>
      <GlobalStyles />
      {routes}
    </HindawiApp>
  </ThemeProvider>
)

Root.propTypes = {
  routes: PropTypes.node.isRequired,
  theme: PropTypes.object.isRequired,
}

const GlobalStyles = createGlobalStyle`
  ${normalize}

  body {
    height: 100vh;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  .ant-input-group-addon {
      background-color: var(--color-neutral-grey-70);
  }

  .ant-input--disabled .ant-input-group-addon {
      background-color: var(--color-neutral-grey-30);
  }

  .ant-input--disabled .ant-input-group-addon__cta {
      cursor: not-allowed;
      color: var(--color-neutral-grey-50);
      background-color: var(--color-neutral-grey-30);
  }

  .ant-anchor {
      cursor: pointer !important;
  }

  .ant-anchor--busy {
      cursor: wait !important;
  }

  .ant-popover-inner-content {
      width: fit-content !important;
      padding: 0 !important;
  }

  .ant-popover-inner {
      width: fit-content !important;
  }

  .ant-popover-placement-bottom .ant-popover-arrow {
      left: 155px !important;
  }

  .bulk-import-editor-button .anticon + span {
      display: none;
  }

  /* WebKit, Blink */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
  }

  /* FF */
  input[type="number"] {
      -moz-appearance: textfield;
  }
`

export default Root
