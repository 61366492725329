import React from 'react'
import { FieldArray } from 'formik'
import styled from 'styled-components'
import { isNumber, get, isString } from 'lodash'
import { useJournal } from '../../../component-journal-info'
import { compose, setDisplayName, withHandlers } from 'recompose'
import { Item, Text, Label, ActionLink, AuthorCard } from '@hindawi/ui'
import {
  IconExpand,
  IconExternalLink,
  Row,
  Space as _Space,
} from '@hindawi/phenom-ui'
import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable'
import { DndContext } from '@dnd-kit/core'
import {
  restrictToVerticalAxis,
  restrictToParentElement,
} from '@dnd-kit/modifiers'
const Space = styled(_Space)`
  width: 100%;
`

const UNSAVED_AUTHOR_ID = 'unsaved-author'

export const WizardAuthors = ({
  onEdit,
  addAuthor,
  moveAuthor,
  formValues,
  journal,
  onSaveAuthor,
  wizardErrors,
  onCancelEdit,
  onDeleteAuthor,
  isAuthorEmailEditable,
}) => {
  const journalCode = get(journal, 'code', '')
  const {
    links: { journalAuthorsGuidelinesLink },
  } = useJournal()
  const buildLink = ({ link, journalCode }) =>
    link.replace('{journalCode}', journalCode)

  const onDragEnd = (formikMove, event, authors) => {
    const { over, active } = event
    if (over && active.id !== over.id) {
      const startIndex = authors.findIndex((author) => author.id === active.id)
      const endIndex = authors.findIndex((author) => author.id === over.id)
      moveAuthor(formikMove)(startIndex, endIndex)
    }
  }
  return (
    <FieldArray name="authors">
      {({ form, move, push, remove, replace, unshift }) => {
        const authors = get(form, 'values.authors', [])
        return (
          <Space direction="vertical" size={8}>
            <Row justify="start" align="middle">
              <Row align="middle">
                <Label mr={2}>Authors</Label>
                <ActionLink
                  data-test-id="submission-add-author"
                  disabled={get(formValues, 'isEditing', false)}
                  fontSize="12px"
                  fontWeight="bold"
                  onClick={addAuthor(unshift)}
                >
                  <Row align="middle">
                    <IconExpand />
                    ADD AUTHOR
                  </Row>
                </ActionLink>
              </Row>
              <Item justify="flex-end">
                <ActionLink
                  iconPosition="right"
                  to={buildLink({
                    link: journalAuthorsGuidelinesLink,
                    journalCode,
                  })}
                >
                  Journal Author Submission Guidelines <IconExternalLink />
                </ActionLink>
              </Item>
            </Row>
            {authors.length > 0 && (
              <Root data-test-id="author-list">
                <DndContext
                  modifiers={[restrictToVerticalAxis, restrictToParentElement]}
                  onDragEnd={(event) => {
                    onDragEnd(move, event, authors)
                  }}
                >
                  <SortableContext
                    items={authors}
                    strategy={verticalListSortingStrategy}
                  >
                    {authors.map((author, i) => (
                      <AuthorCard
                        index={i}
                        item={author}
                        key={author.id}
                        onCancelEdit={onCancelEdit(authors, remove)}
                        onDeleteAuthor={onDeleteAuthor({ remove })}
                        onEdit={onEdit}
                        onSaveAuthor={onSaveAuthor({ push, remove, replace })}
                        isAuthorEmailEditable={isAuthorEmailEditable}
                        isEditingForm={get(form, 'values.isEditing', false)}
                        authorsLength={authors.length}
                        {...author}
                      />
                    ))}
                  </SortableContext>
                </DndContext>
                {get(wizardErrors, 'isEditing', false) && (
                  <Row justify="start">
                    <Text error>You have an unsaved author.</Text>
                  </Row>
                )}
              </Root>
            )}

            {get(wizardErrors, 'authors', false) &&
              isString(wizardErrors.authors) && (
                <Row justify="start">
                  <Text error>{wizardErrors.authors}</Text>
                </Row>
              )}
          </Space>
        )
      }}
    </FieldArray>
  )
}

export default compose(
  setDisplayName('WizardAuthors'),
  withHandlers({
    addAuthor:
      ({ setWizardEditMode }) =>
      (arrayAdderFn) =>
      () => {
        setWizardEditMode(true)
        arrayAdderFn({
          id: UNSAVED_AUTHOR_ID,
          email: '',
          country: '',
          lastName: '',
          firstName: '',
          affiliation: '',
        })
      },
    moveAuthor: () => (formMoveFn) => (startIndex, endIndex) => {
      if (isNumber(startIndex) && isNumber(endIndex)) {
        formMoveFn(startIndex, endIndex)
      }
    },
    onCancelEdit:
      ({ setWizardEditMode }) =>
      (authors, arrayRemoverFn) =>
      (authorIndex) => {
        if (authors[authorIndex].id === UNSAVED_AUTHOR_ID) {
          arrayRemoverFn(authorIndex)
        }
        setWizardEditMode(false)
      },
    onEdit:
      ({ setWizardEditMode }) =>
      () => {
        setWizardEditMode(true)
      },
    onDeleteAuthor:
      ({ onDeleteAuthor, setWizardEditMode }) =>
      (formFns) =>
      (author, props) => {
        onDeleteAuthor(author, {
          ...props,
          formFns,
          setWizardEditMode,
        })
      },
    onSaveAuthor:
      ({ onSaveAuthor, onEditAuthor, setWizardEditMode }) =>
      (formFns) =>
      (author, { setEditMode, ...props }) => {
        const setEdit = () => {
          setWizardEditMode(false)
          setEditMode(false)
        }
        if (author.id === UNSAVED_AUTHOR_ID) {
          onSaveAuthor(author, {
            ...props,
            formFns,
            setWizardEditMode: setEdit,
          })
        } else {
          onEditAuthor(author, {
            ...props,
            formFns,
            setWizardEditMode: setEdit,
          })
        }
      },
  }),
)(WizardAuthors)

// #region styles
const Root = styled.div`
  background-color: #ecf0f3;
  border-radius: 4px;
  margin-bottom: calc(4px * 4);
  padding: calc(4px * 2);
`
// #endregion
