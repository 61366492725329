import React, { Fragment } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

import {
  Row,
  Item,
  Label,
  Text,
  validators,
  AutoValidatedFormikField,
} from '@hindawi/ui'

const StyledLabel = styled(Label)`
  strong {
    margin-right: 4px;
  }
  h4 {
    font-weight: 400;
  }
`

export const WizardPreprint = ({
  preprintValue,
  preprintDescription,
  preprints,
  setFieldValue,
}) => {
  const formats = preprints.map(({ format }) => format)

  const validator = validators.oneOfTheFormats({
    formats,
    errorMessage: 'Incomplete ID',
  })
  const fieldName = 'preprintValue'
  return (
    <Fragment>
      <Row alignItems="center">
        <Item data-test-id="submission-title" flex={3} mr={2} mt={6} vertical>
          <StyledLabel height={0}>
            <strong>Link your submission with a preprint</strong> (optional)
          </StyledLabel>
          <Text secondary>
            <br />
            <div dangerouslySetInnerHTML={{ __html: preprintDescription }} />
          </Text>
          <Row justify="flex-start" mb={0} width={50}>
            <AutoValidatedFormikField
              errorType="warning"
              icon="solidInfo"
              name={fieldName}
              placeholder="arXiv:YYMM.NNNNN[category]"
              setFieldValue={setFieldValue}
              validators={[validator]}
              value={preprintValue}
            />
          </Row>
        </Item>
      </Row>
    </Fragment>
  )
}

WizardPreprint.propTypes = {
  preprintValue: PropTypes.string,
  preprintDescription: PropTypes.string,
  preprints: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string,
      format: PropTypes.string,
    })
  ).isRequired,
  setFieldValue: PropTypes.func.isRequired,
}

WizardPreprint.defaultProps = {
  preprintDescription: null,
  preprintValue: '',
}
