import React, { Component, ReactElement } from 'react'

export class ErrorBoundary extends Component<{
  children: ReactElement
  fallback: ReactElement
}> {
  state = {
    hasError: false,
  }

  componentDidCatch() {
    this.setState({ hasError: true })
  }

  render() {
    if (this.state.hasError) {
      return this.props.fallback
    }
    return this.props.children
  }
}
