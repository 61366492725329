import React from 'react'
import { omit } from 'lodash'
import { space } from 'styled-system'
import styled from 'styled-components'

import { positionHelper } from './styledHelpers'

// eslint-disable-next-line react/display-name
export default ({
  icon,
  size,
  onClick,
  primary,
  fontIcon,
  disabled,
  unclickable,
  iconSize = 1.8,
  className,
  ...props
}) => {
  const rest = omit(props, 'data-test-id')
  return (
    <IconButton
      className={className}
      data-test-id={props['data-test-id']}
      disabled={disabled}
      display="inline"
      onClick={disabled ? null : onClick}
      primary={primary}
      unclickable={unclickable}
      {...rest}
    >
      {icon && icon()}
      {fontIcon && (
        <FontIconButton
          className={`${fontIcon} fontIconStyle`}
          primary={primary}
          size={iconSize}
          {...rest}
        />
      )}
    </IconButton>
  )
}

const FontIconButton = styled.span`
  font-size: calc(${(props) => props.size} * 4px);
  color: ${(props) => props.primary && '#81BA40'};

  ${space};
`

export const IconButton = styled.div`
  align-items: center;
  cursor: pointer;
  display: flex;
  justify-content: center;
  opacity: ${(props) => (props.disabled ? 0.7 : 1)};
  pointer-events: ${(props) => (props.unclickable ? 'none' : 'auto')};
  &:hover {
    opacity: ${(props) => (props.noHover ? 1 : 0.7)};
  }

  &[disabled] {
    cursor: not-allowed;
  }
  ${space};
  ${positionHelper};
`
