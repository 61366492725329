import React from 'react'
import { Route } from 'react-router-dom'
import { Footer } from '@hindawi/ui'
import styled from 'styled-components'
import { AppBar } from '../../component-authentication/client'

import { useJournal } from '../../component-journal-info'
import { KeycloakProvider } from '../../component-sso/client'

const HideOnPath = ({ component: Component, pathnames }) => (
  <Route
    render={({ location }) => {
      if (pathnames.includes(location.pathname)) return null
      return <Component />
    }}
  />
)

const App = ({ children }) => {
  const {
    supportEmail,
    links: { privacyLink, termsLink },
  } = useJournal()

  const hiddenAppBarPaths = ['/404', '/subscribe', '/unsubscribe']
  const hiddenFooterPaths = ['/404']

  return (
    <Root>
      {/* Both <AppBar> and <PageContent> children need access to the Keycloak instance, so the provider cannot be nested deeper than this */}
      <KeycloakProvider>
        <HideOnPath
          component={() => <AppBar />}
          pathnames={hiddenAppBarPaths}
        />

        <PageContent>{children}</PageContent>

        <HideOnPath
          component={() => (
            <Footer
              privacyLink={privacyLink}
              supportEmail={supportEmail}
              termsLink={termsLink}
            />
          )}
          pathnames={hiddenFooterPaths}
        />
      </KeycloakProvider>
    </Root>
  )
}

export default App

const Root = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #f5f5f5;
  font-family: 'Nunito', sans-serif;
  font-size: 14px;
  color: #4f4f4f;
  line-height: 19px;
  min-height: 100vh;
`

const PageContent = styled.main`
  flex: 1;
`
