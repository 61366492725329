import React, { useState } from 'react'
import { space } from 'styled-system'
import styled from 'styled-components'

const ClockIcon = (props) => {
  return (
    <SVG
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M8.02436 3.9694C7.74822 3.96835 7.52351 4.19136 7.52247 4.4675L7.51123 7.43112C7.35129 7.56867 7.25 7.7725 7.25 8C7.25 8.41421 7.58579 8.75 8 8.75C8.13837 8.75 8.26798 8.71253 8.37925 8.64719L11.9163 10.9323C12.1482 11.0821 12.4577 11.0156 12.6076 10.7836C12.7574 10.5517 12.6909 10.2422 12.4589 10.0923L8.65536 7.63503C8.61705 7.56638 8.56826 7.50437 8.51116 7.45117L8.52246 4.47129C8.52351 4.19515 8.3005 3.97044 8.02436 3.9694Z"
        fill={props.color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 0C12.4183 0 16 3.58172 16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8C0 3.58172 3.58172 0 8 0ZM8.5 1.01758C11.9675 1.2623 14.7377 4.03251 14.9824 7.5H14.5C14.2239 7.5 14 7.72386 14 8C14 8.27614 14.2239 8.5 14.5 8.5H14.9824C14.7377 11.9675 11.9675 14.7377 8.5 14.9824V14.5C8.5 14.2239 8.27614 14 8 14C7.72386 14 7.5 14.2239 7.5 14.5V14.9824C4.03251 14.7377 1.2623 11.9675 1.01758 8.5H1.5C1.77614 8.5 2 8.27614 2 8C2 7.72386 1.77614 7.5 1.5 7.5H1.01758C1.2623 4.03251 4.03251 1.2623 7.5 1.01758V1.5C7.5 1.77614 7.72386 2 8 2C8.27614 2 8.5 1.77614 8.5 1.5V1.01758Z"
        fill={props.color}
      />
    </SVG>
  )
}

export default ClockIcon

// #region styles
const SVG = styled.svg`
  ${space};
`
// #endregion
