import { withStateHandlers } from 'recompose'

export const withSteps = withStateHandlers(
  {
    step: 0,
  },
  {
    nextStep:
      ({ step }) =>
      () => ({
        step: step + 1,
      }),
    prevStep:
      ({ step }) =>
      () => ({
        step: Math.max(0, step - 1),
      }),
  },
)
