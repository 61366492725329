const REVIEWERS_GUIDELINES_LINK =
  'https://www.hindawi.com/publish-research/reviewers/'
const EDITORIAL_THRESHOLD_GUIDE_LINK =
  'https://editors.hindawi.com/page/editorial-threshold'
const PEER_REVIEW_CHECKLIST_LINK =
  'https://downloads.hindawi.com/peer_review_checklist.pdf'
const WILEY_PEER_REVIEW_RESOURCES =
  'https://authorservices.wiley.com/Reviewers/journal-reviewers/how-to-perform-a-peer-review/index.html'
module.exports = {
  name: 'Hindawi',
  supportEmail: 'help@hindawi.com',
  pageTitle: 'Hindawi Review',
  links: {
    transferManuscriptLink: 'https://www.hindawi.com/transfer-manuscript',
    privacyLink: 'https://www.hindawi.com/privacy/',
    termsLink: 'https://www.hindawi.com/terms/',
    ethicsLink: 'https://www.hindawi.com/ethics/',
    coiLink: 'https://authorservices.wiley.com/ethics-guidelines/index.html',
    peerReviewGuidelinesLink:
      'https://authorservices.wiley.com/Reviewers/journal-reviewers/how-to-perform-a-peer-review/general-and-ethical-guidelines.html',
    dataAvailabilityLink:
      'https://www.hindawi.com/publish-research/authors/research-data/',
    apcLink: 'https://www.hindawi.com/journals/{journalCode}/apc',
    authorsGuidelinesLink: 'https://www.hindawi.com/publish-research/authors/',
    journalAuthorsGuidelinesLink:
      'https://www.hindawi.com/journals/{journalCode}/guidelines/',
    reviewLink: 'https://review.wiley.com',
    invoicingLink: 'https://invoicing.hindawi.com',
    authorServiceLink:
      'https://www.hindawi.com/publish-research/authors/author-services/?utm_source=email&utm_medium=marketing%20referral&utm_campaign=HDW_MRKT_GBL_AWA_EMIL_OWN_AUTH_HIND_X2_10170',
    editorCommunityLink:
      'https://editors.hindawi.com/page/phenom-guide-for-LGEs',
  },
  emailData: {
    logo: 'https://static.hindawi.com/logo-hindawi-194-below.png',
    ctaColor: '#63a945',
    logoLink: 'https://hindawi.com',
    shortReviewLink: 'review.wiley.com',
    staffEmail: 'reviewhelp@wiley.com',
    privacy:
      'Hindawi respects your right to privacy. Please see our <a style="color: #007e92; text-decoration: none;" href="https://www.hindawi.com/privacy/">privacy policy</a> for information on how we store, process, and safeguard your data.',
    address:
      'Hindawi Limited, 3rd Floor, Adam House, 1 Fitzroy Square, London, W1T 5HF, United Kingdom',
    publisher: 'Hindawi Limited',
    footerText: {
      unregisteredUsers: `This email was sent to {recipientEmail}. You have received this email in regards to the account creation, submission, or peer review process of a submitted paper, published by Hindawi Limited.`,
      registeredUsers: `This email was sent to {recipientEmail}. You have received this email in regards to the account creation, submission, or peer review process of a submitted paper, published by Hindawi Limited.`,
    },
    communicationServiceMailLogo:
      'https://static.hindawi.com/logo-hindawi-194-below.png',
  },
  recommendationScreenInfoBox: {
    HINDAWI: {
      title:
        'Your report should review the research presented in the manuscript, and provide detailed and constructive feedback to the author(s).',
      description: `
            <p>Research published in the journal must be:</p>
            <ul>
              <li>Ethically sound; </li>
              <li>Scientifically valid; </li>
              <li>Technically accurate in its methods and results; </li>
              <li>In scope; </li>
              <li>Representative of a specific advance, or replication, or null/negative result; </li>
              <li>As reproducible as possible; </li>
              <li>Journals that are not categorized as sound science may also consider novelty or perceived impact/interest when making a decision.  </li>
            </ul>
               <p style="margin-bottom: 8px">For guidance on constructing a high-quality review report, please see 
               <a href=${WILEY_PEER_REVIEW_RESOURCES} target="_blank">Wiley’s peer review resources</a>.
               </p>
              <strong>A report that only suggests grammar and formatting corrections may not be considered.</strong>`,
    },
    WILEY: {
      title:
        'Your report should review the research presented in the manuscript, and provide detailed and constructive feedback to the author(s).',
      description: `
    <p>Research published in the journal must be:</p>
    <ul>
      <li>Ethically sound; </li>
      <li>Scientifically valid; </li>
      <li>Technically accurate in its methods and results; </li>
      <li>In scope; </li>
      <li>Representative of a specific advance, or replication, or null/negative result; </li>
      <li>As reproducible as possible; </li>
      <li>Journals that are not categorized as sound science may also consider novelty or perceived impact/interest when making a decision.  </li>
    </ul>
       <p style="margin-bottom: 8px">For guidance on constructing a high-quality review report, please see 
       <a href=${WILEY_PEER_REVIEW_RESOURCES} target="_blank">Wiley’s peer review resources</a>.
       </p>
       <strong>A report that only suggests grammar and formatting corrections may not be considered.</strong>`,
    },
    getDescriptionWithLink(link) {
      return (
        this.description +
        `<p>For more information on these review questions, please see this article on our <a href=${link} title="Reviewer Hub" target="_blank" rel="noreferrer">[Reviewer Hub]</a>.</p>`
      )
    },
  },
}
