/* eslint-disable sonarjs/cognitive-complexity */
import React, { useRef, useState, useEffect, LegacyRef } from 'react'
import { DateService } from '../../../../component-date-service'
import {
  IconWarningSolid as _IconWarningSolid,
  Popover,
} from '@hindawi/phenom-ui'
import styled from 'styled-components'

const IconWarningSolid = styled(_IconWarningSolid)`
  svg {
    width: 16px;
    height: 15px;
  }
`
const WrapperPopover = styled.div`
  .ant-popover-placement-bottom .ant-popover-arrow {
    left: 155px !important;
  }
  .ant-popover-placement-top .ant-popover-arrow {
    left: 155px !important;
  }
  .icon-warn {
    margin-right: 3px;
  }
`
const WrapperContent = styled.div`
  padding: 12px;
  width: 320px;
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 20px !important;
  font-family: Nunito !important;
`
const LabelText = styled.div`
  color: #0a7388;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  white-space: nowrap;
  height: 20px;
`

const WarningLabelText = styled(LabelText)`
  color: #ff5547;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  white-space: nowrap;
`

const generateRevisionMessageLabel = ({ isDeadlinePassed, quantity, unit }) => {
  if (isDeadlinePassed) {
    return `${quantity} ${unit} overdue`
  } else {
    return `${quantity} ${unit} to submit`
  }
}

const generateRevisionMessagePopover = ({
  currentUserRole,
  isDeadlinePassed,
  quantity,
  unit,
  eaEmail = '[EA email]',
}) => {
  const isLessThan30Days =
    (quantity < 30 && unit === 'days') ||
    ['seconds', 'minutes', 'hours'].includes(unit)
  if (['editorialAssistant', 'admin'].includes(currentUserRole)) {
    if (isDeadlinePassed) {
      if (isLessThan30Days) {
        return (
          <p style={{ marginBottom: '0px' }}>
            The author has exceeded the deadline by {quantity} {unit}.{' '}
          </p>
        )
      }
      return (
        <p style={{ marginBottom: '0px' }}>
          The author has exceeded the deadline by {quantity} {unit}. The
          manuscript will be considered for withdrawal.
        </p>
      )
    } else {
      return (
        <p style={{ marginBottom: '0px' }}>
          The author has {quantity} {unit} left to submit the revised
          manuscript.
        </p>
      )
    }
  }

  if (currentUserRole === 'author') {
    if (isDeadlinePassed) {
      if (isLessThan30Days) {
        return (
          <>
            <p>
              You are {quantity} {unit} overdue to submit the revised
              manuscript.
            </p>
            <p style={{ marginBottom: '0px' }}>
              Please send an email to your assigned Editorial Assistant{' '}
              {eaEmail} and request a deadline extension.
            </p>
          </>
        )
      }
      return (
        <>
          <p>
            You are {quantity} {unit} overdue to submit the revisd manuscrit.
          </p>
          <p>
            {' '}
            The manuscript will be considerd for withdrawal.\n Please send an
            email to your assigned Editorial Assistant {eaEmail} and request a
            deadline extension.
          </p>
        </>
      )
    } else {
      return (
        <>
          <p>
            You have {quantity} {unit} left to submit the revised manuscript.
          </p>
          <p style={{ marginBottom: '0px' }}>
            If you are unable to submit the revision in the available time
            please send an email to your assigned Editorial Assistant {eaEmail}{' '}
            and request a deadline extension.
          </p>
        </>
      )
    }
  }

  if (isDeadlinePassed) {
    if (isLessThan30Days) {
      return (
        <p style={{ marginBottom: '0px' }}>
          The author has exceeded the deadline by {quantity} {unit}.
        </p>
      )
    }
    return (
      <p style={{ marginBottom: '0px' }}>
        The author has exceeded the deadline by {quantity} {unit}. The
        manuscript will be considered for withdrawal.
      </p>
    )
  } else {
    return (
      <p style={{ marginBottom: '0px' }}>
        The author has {quantity} {unit} left to submit the revised manuscript.
      </p>
    )
  }
}
const getPosition = ({
  isExtendDeadlineOpen,
  isDeadlinePassed = false,
  placement = 'bottom',
  labelWidth = 112,
  labelHeight = 20,
}) => {
  const customPosition = isExtendDeadlineOpen ? 'top' : placement
  const translateX = isDeadlinePassed ? -labelWidth / 2 - 19 : -labelWidth / 4
  const translateY = isExtendDeadlineOpen
    ? isDeadlinePassed
      ? labelHeight / 2 - 8
      : labelHeight / 2 - 10
    : isDeadlinePassed
      ? -labelHeight / 2 + 6
      : -labelHeight / 2 + 10
  const align = {
    offset: [translateX, translateY],
  }
  return { placement: customPosition, align, arrowPointAtCenter: true }
}
export function RevisionDeadlineMessage({
  currentUserRole,
  revisionDeadlineDate,
  editorialAssistant,
  isExtendDeadlineOpen = false,
  placement = 'bottom',
}) {
  const eaEmail = editorialAssistant?.alias.email
  const labelRef = useRef<{
    offsetWidth: number
    offsetHeight: number
  }>()
  const [position, setPosition] = useState({})
  useEffect(() => {
    if (!labelRef.current) return
    setPosition(
      getPosition({
        isExtendDeadlineOpen,
        isDeadlinePassed,
        placement,
        labelWidth: labelRef.current.offsetWidth,
        labelHeight: labelRef.current.offsetHeight,
      }),
    )
  }, [isExtendDeadlineOpen])
  if (!currentUserRole) {
    return null
  }
  if (revisionDeadlineDate === undefined || revisionDeadlineDate === null) {
    return null
  }

  const revisionDeadline = new Date(revisionDeadlineDate)
  const currentDate = new Date()

  let isDeadlinePassed = false

  let duration = DateService.duration(currentDate, revisionDeadline)

  if (revisionDeadline < currentDate) {
    duration = DateService.duration(revisionDeadline, currentDate)
    isDeadlinePassed = true
  }

  const quantity =
    duration.years > 0 || duration.months > 0 || duration.days > 0
      ? duration.asDays()
      : duration.hours > 0
        ? duration.hours
        : duration.minutes

  let unit =
    duration.years > 0 || duration.months > 0 || duration.days > 0
      ? 'days'
      : duration.hours > 0
        ? 'hours'
        : 'minutes'

  if (quantity === 1) unit = unit.slice(0, -1)

  const warningLabelText = (
    <WarningLabelText>
      <IconWarningSolid className="icon-warn" />
      {generateRevisionMessageLabel({ isDeadlinePassed, quantity, unit })}
    </WarningLabelText>
  )

  const labelText = (
    <LabelText>
      {generateRevisionMessageLabel({ isDeadlinePassed, quantity, unit })}
    </LabelText>
  )

  const label = isDeadlinePassed ? warningLabelText : labelText

  const content = generateRevisionMessagePopover({
    currentUserRole,
    isDeadlinePassed,
    quantity,
    unit,
    eaEmail,
  })

  return (
    <WrapperPopover>
      <Popover
        content={<WrapperContent>{content}</WrapperContent>}
        trigger="hover"
        getPopupContainer={(triggerNode) =>
          triggerNode.parentNode as HTMLElement
        }
        {...position}
      >
        <span ref={labelRef as LegacyRef<HTMLSpanElement>}>{label}</span>
      </Popover>
    </WrapperPopover>
  )
}
