import React from 'react'
import styled from 'styled-components'

import { Row, Item, SingleActionModal, Text } from '@hindawi/ui'

const CallForPapersModal = ({ hideModal, callForPapers }) => (
  <SingleActionModal
    confirmText="I have read this Call for Papers"
    content={() => (
      <Row>
        <StyledItem height={95} mb={8} mt={2} vertical>
          <Text whiteSpace="pre-wrap">{callForPapers}</Text>
        </StyledItem>
      </Row>
    )}
    hasCloseIcon={false}
    hideModal={hideModal}
    title="Call for Papers"
    width={130}
  />
)

export default CallForPapersModal

const StyledItem = styled(Item)`
  overflow: scroll;
  border: 1px solid #c9c9c9;
  border-radius: 4px;
  padding: 8px;
`
