import React from 'react'
import Tippy from '@tippy.js/react'
import { Space, Text } from '@hindawi/phenom-ui'
import { AuthorTag } from '.'
import { Author } from '@shared/ui/types'

const AuthorTooltipBody = ({ author }: { author: Author }) => (
  <Space direction="vertical" size={4}>
    <AuthorTag author={author} />
    <Text>{author.alias.email}</Text>
    <Text>{author.alias.aff}</Text>
  </Space>
)

export function AuthorTagWithTooltip({
  author,
}: {
  author: Author
}): React.ReactNode {
  return (
    <Tippy
      arrow
      content={<AuthorTooltipBody author={author} />}
      placement="bottom"
      theme="light"
    >
      <span data-test-id={`author-tag-with-tooltip-${author.id}`}>
        <AuthorTag author={author} />
      </span>
    </Tippy>
  )
}
