import { space } from 'styled-system'
import styled from 'styled-components'

const Bullet = styled.span.attrs((props) => ({
  children: '\u2022',
}))`
  color: #4f4f4f;
  font-size: 14px;

  ${space};
`
export default Bullet
